import { AiOutlineClose } from 'react-icons/ai'
import { Component } from 'react'

interface IProps {
  handleClose?: () => void
}

interface IState {

}

export default class Modal extends Component<IProps, IState> {

  render() {

    return (
      <div className={`fixed inset-0 z-90 bg-alis-dark bg-opacity-40`}>
        <div className="relative h-full w-full grid">
          <div className='absolute inset-0' onClick={this.props.handleClose}></div>
          <div className='absolute text-black top-4 right-4'>
            <button className='z-100 relative' type="button" onClick={this.props.handleClose}>
              <AiOutlineClose />
            </button>
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

