import { Component } from 'react'
import { VscFilePdf } from 'react-icons/vsc'
import { ISystem, IItem } from '@app/utils/types'

interface IProps {
    clicked: (data: any) => void
    project_id: string
    text?: string
    data: ISystem | IItem
    hasPdf: boolean
}

interface IState {
}

export default class DocPreview extends Component<IProps, IState> {

    render() {
        return (
            <div>
                { this.props.data.hasPdf &&
                    <VscFilePdf className="text-slate-500 mt-2" size={25} onClick={() => this.props.clicked(this.props.data)} />
                }
            </div>
        )
    }

}
