import { HTMLAttributes, Fragment, MouseEventHandler } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { colors } from '@utils/const';

interface Props extends HTMLAttributes<HTMLDivElement> {
    //boolean to always open ddm (for presentation)
    forceOpen?: boolean;
    label?: string;
    withDivider?: boolean;
    items: DDMItem[];
}

export interface DDMItem {
    icon?: JSX.Element,
    text: string,
    desc?: string,
    link?: string,
    onClick?: MouseEventHandler
}


function AppDropdown(props: Props) {
    return (
        <Menu as="div" className={`relative`}>
            <Menu.Button className="w-full rounded-none hover:bg-opacity-10 hover:bg-white">{props.children ? props.children : 'Menu'}</Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >

                <Menu.Items as="ul" className="absolute right-0 origin-top-right divide-y divide-gray-100 shadow-lg focus:outline-none">
                    {props.items.map(
                        (item, index) => {
                            return (<Menu.Item as="li" className="p-2 hover:bg-opacity-10 hover:bg-white" key={index}>
                                {({ active }) => (
                                    <button {...item}>
                                        {item.icon ? item.icon : null} {item.text}
                                    </button>
                                )}
                            </Menu.Item>)
                        })}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
export default AppDropdown;