import { Component } from 'react'
import SectorTemplate from './SectorTemplate'
import GridTemplate from './GridTemplate'
import API from '@app/services/ApiService'
import Excel from '@utils/functions/excel'
import SystemGrid from '@components/grids/SystemsGrid'
import DocumentsGrid from '@app/components/grids/DocumentsGrid'

interface IGridProps {
    project_id: string
}

interface IGridState {

}

export default class Evolis extends Component<IGridProps, IGridState> {

    state = {
        toggleState: 1,
    }

    render() {

        const toggleTab = (index: any) => {
            this.setState({ toggleState: index });
        };

        return (

            <div>
                <div className="container">
                    <div className="bloc-tabs">
                        <button
                            className={this.state.toggleState === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(1)}
                        >
                            GENERAL INFO
                        </button>
                        <button
                            className={this.state.toggleState === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(2)}
                        >
                            SYSTEMS
                        </button>
                        <button
                            className={this.state.toggleState === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleTab(3)}
                        >
                            DOCUMENTS
                        </button>
                    </div>

                    <div className="content-tabs">

                        <div className={this.state.toggleState === 1 ? "content active-content" : "content"} >

                            <div className='grid grid-cols-2'>
                                <div>
                                    <h1 className='font-bold text-lg'>Sector</h1>
                                    <div className="grid grid-cols-6 font-sm text-gray-500">
                                        <h1>Site Reference</h1>
                                        <h1>Ev Reference</h1>
                                        <h1>Ev Description</h1>
                                        <h1>Ev Filename</h1>
                                    </div>
                                    <SectorTemplate project_id={this.props.project_id}></SectorTemplate>
                                </div>

                                <div className='flex'>
                                    <div>
                                        <h1 className='font-bold text-lg'>Grid</h1>
                                        <div className="grid grid-cols-9 font-sm text-sm text-gray-500">
                                            <h1>Ev Def</h1>
                                            <h1>Ev Desc</h1>
                                            <h1>Ev Site</h1>
                                            <h1>Ev Sector</h1>
                                            <h1>Ev Level</h1>
                                            <h1>Ev Grid</h1>
                                            <h1>Ev FileName</h1>
                                        </div>
                                        <GridTemplate project_id={this.props.project_id}></GridTemplate>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={this.state.toggleState === 2 ? "content  active-content" : "content"} >
                            <div className="h-full">
                                <SystemGrid project_id={this.props.project_id} />
                            </div>
                        </div>

                        <div className={this.state.toggleState === 3 ? "content  active-content" : "content"} >
                            <div className="h-full">
                                <DocumentsGrid destination={"exportEvolis"} hideColumn={false} project_id={this.props.project_id} />
                            </div>
                        </div>
                    </div>
                </div>

                <style>{`
                    .container {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        background: white;
                        margin: 20px auto 0;
                        word-break: break-all;
                    }
                    
                    .bloc-tabs {
                        display: flex;
                    }
                    
                    .tabs {
                        padding: 15px;
                        text-align: center;
                        width: 50%;
                        background: rgba(128, 128, 128, 0.075);
                        cursor: pointer;
                        box-sizing: content-box;
                        position: relative;
                        outline: none;
                    }
                    
                    .active-tabs  {
                        background: white;
                        border-bottom: 1px solid transparent;
                    }
                    
                    .active-tabs::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: -5px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: calc(100% + 2px);
                        height: 5px;
                        background: rgb(43, 210, 255);
                    }

                    .content {
                        background: white;
                        padding: 20px;
                        width: 100%;
                        height: 100%;
                        display: none;
                    }
                    
                    .active-content {
                        display: inline-block;
                        width: 100%;
                        height: 89vh;
                    }
                `}</style>
            </div>
        )
    }
}

