import { Component, MouseEvent } from 'react'

import { User } from '@services/UserService'

import { MdUpdate as UpdateIcon } from 'react-icons/md';
import { FaTrash as BinIcon } from 'react-icons/fa';
import { IUser } from '@utils/types';

interface IProps {
    user: IUser, 
    onUpdate: () => void
    onDelete: () => void
}

export default class ProjectCard extends Component<IProps> {
    state = {
        modal: false
    }

    onUpdate(e: MouseEvent<HTMLButtonElement>){
        e.preventDefault()
        this.props.onUpdate()
    }

    onDelete(e: MouseEvent<HTMLButtonElement>){
        e.preventDefault()
        this.props.onDelete()
    }

    render() {
        return (
            <div className="h-full w-full sm:h-1/2 sm:w-1/4 min-h-20 bg-cover">
                    <div className="relative h-full w-full flex flex-col text-center text-lg bg-gray-700 bg-opacity-80 hover:bg-opacity-30 transition-all duration-1000">
                        {User.isAdmin &&
                            <div className="flex" >
                                <button onClick={this.onUpdate.bind(this)} className="flex flex-grow px-2 bg-opacity-30 bg-gray-700 hover:bg-gray-600" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span>Update</span> <UpdateIcon />
                                </button>

                                <button onClick={this.onDelete.bind(this)} className="flex flex-grow px-2 bg-opacity-30 bg-gray-700 hover:bg-gray-600" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span>Delete</span> <BinIcon />

                                </button>
                            </div>
                        }
                        <div className="flex flex-grow justify-center">
                            <span className="self-center text font-bold text-white shadow-inner">
                                {this.props.user.username}
                            </span>
                        </div>
                    </div>
            </div>
        )
    }
}
