import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";

import TopBar from '@components/menu/TopBar'
import SideDrawer from '@components/menu/SideDrawer'

function Admin() {
    
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    
    let content = null

    return (

        <div className="h-full w-full flex flex-col text-alis-2 overflow-hidden">
            <div className="w-full z-10">
                <TopBar auth={true} title="Admin panel" />
                {content}
            </div>
            <Router>
                <div className="flex flex-shrink flex-grow overflow-auto">
                    <SideDrawer >
                        <ul className="w-36">
                            <li>
                                <Link to={`${location.pathname}?view=users`}>Users</Link>
                            </li>
                        </ul>
                    </SideDrawer>
                    <div className="bg-gray-900"></div>
                </div>
            </Router>
        </div>
    )
}

export default Admin
