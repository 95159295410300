import { Component } from 'react';
import { AgGridEvent, ColumnApi, GridApi } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import DocumentsGrid from '@components/grids/DocumentsGrid'

import { CgFileDocument } from 'react-icons/cg'

import { add } from "@services/NotificationService";

import API from '@services/ApiService';
import { IDocument, IProject } from '@utils/types'


interface IGridProps {
    project_id: string
}

interface IGridState {
    data?: IDocument[]
    actualProject?: IProject
}

export default class Grid extends Component<IGridProps, IGridState> {

    gridApi?: GridApi
    columnApi?: ColumnApi
    autoSize = false

    constructor(props: IGridProps) {
        super(props)

        this.state = {}
        this.getData()

        //Call the function that retrieves the actual project
        this.getActualProject();
    }

    async getActualProject() {
        const response = await API.get(`/projects/${this.props.project_id}`)
        if (response.status === 200) {
            const actualProject = response.data
            this.setState({ actualProject })
        }
    }

    render() {
        const defaultColDef = {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        };

        return (
            <div className="ag-theme-material h-full w-full flex flex-col">

                <div className='columns-2 pt-6 pb-6 align-middle relative pl-4 bg-white'>
                    <h2 className='text-alis-2 font-medium ml-2 text-sm'>{this.state.actualProject?.name}</h2>
                    <div className='text-black'>
                        <h1 className='font-bold ml-2 text-base'>DOCUMENTS REGISTER</h1>

                        <div className='flex items-start space-x-2 font-semibold relative text-sm'>
                            <CgFileDocument className='w-5 h-5'></CgFileDocument> <h1>{this.state.data?.length} DOCUMENTS</h1>
                        </div>
                    </div>
                </div>

                <DocumentsGrid destination={"deleteFromProject"} hideColumn={false} project_id={this.props.project_id} />
            </div>
        );
    }

    autoSizeAll(skipHeader = false): void {
        if (!this.columnApi) return

        const columns = this.columnApi.getAllColumns()
        const allColumn = (columns || []).filter((column) => {
            return column.isResizable()
        })
        this.columnApi.autoSizeColumns(allColumn, skipHeader)
    }

    onGridReady(params: AgGridEvent): void {
        // or setState if using components
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.autoSizeAll()
    }

    async getData() {
        try {
            this.autoSize = true
            const response = await API.get(`/projects/${this.props.project_id}/documents`)
            if (response.status === 200) {
                const data = response.data
                this.setState({ data })
            }
        } catch (error) {
            this.autoSize = false
            add({
                message: 'Failed to get project documents!',
                severity: "error"
            },2000)
        }
    }

    async downloadFile(data: IDocument) {
        try {
            const response = await API.get(`/projects/${this.props.project_id}/documents/${data.id}/download`)
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.originalname);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (error) {
            add({
                message: `download failed!`,
                severity: "error"
            },2000)
        }
    }
}
