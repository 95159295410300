import { useEffect, FormEvent } from 'react';

import appLogoNew from '@assets/logo_new.png'
import bgNew from '@assets/login_bg_new.jpg'
import holisLogoNew from '@assets/logo_holis_new.png'

import AppInput, { InputType } from '@app/components/common/AppInput'
import AppSwitch from '@app/components/common/AppSwitch'
import Loader from '@app/components/common/Loader'

import { colors } from '@utils/const';

import { useState } from 'react';

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import notifications from '@services/NotificationService'
import { login, forgotPassword } from '@services/UserService'

let _isMounted: boolean

function Login() {
    const location = useLocation();
    const [isLoading, setLoading] = useState<boolean>(false)

    const [pwdVisible, setPwdVisible] = useState<any>()

    function pwdVisibility() {
        if (pwdVisible == InputType.text) {
            setPwdVisible(InputType.password)
        } else {
            setPwdVisible(InputType.text)
        }
    }

    const loginSubmit = async (e: FormEvent<HTMLFormElement>) => {
        try {
            setLoading(true)
            e.preventDefault()
            const form = e.target as HTMLFormElement

            const formData = new FormData(form)

            const username = String(formData.get('username'))
            const password = String(formData.get('password'))

            const remember = Boolean(formData.get('remember'))

            await login(username, password, remember)
        } catch (error: any) {
            if (error?.status !== 200) {
                notifications.add({
                    message: error.data?.error || 'Login failed!',
                    severity: 'warning'
                },2000)
            }
            console.log(error, _isMounted)
        }
        if (_isMounted) setLoading(false)
    }

    const forgotSubmit = async (e: FormEvent<HTMLFormElement>) => {
        try {
            setLoading(true)
            e.preventDefault()
            const form = e.target as HTMLFormElement

            const email = form['email'].value

            await forgotPassword(email)
        } catch (error: any) {
            if (error.response?.status !== 200) {
                notifications.add({
                    message: 'Login failed!',
                    severity: 'warning'
                },2000)
            }
            console.log(error, _isMounted)
        }
        if (_isMounted) setLoading(false)
    }

    useEffect(() => {
        _isMounted = true;
        return () => { _isMounted = false }
    })

    useEffect(() => {
        setPwdVisible("password")
    }, [])

    return (
        <div className="h-full w-full flex items-center sm:p-10"
            style={{
                backgroundImage: "url(" + bgNew + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
            <div className="w-80 max-h-full m-auto p-5 bg-white opacity-90 flex flex-col">
                <div className=" h-full overflow-auto">
                    <Loader isLoading={isLoading}>

                        <div className="h-32 p-1" >
                            <img src={appLogoNew} className="object-contain h-full w-full mt-2" alt="Alis Logo" />
                        </div>
                        <div className="h-3/6 flex-shrink-1">
                            <Router>
                                <Switch>
                                    <Route path={location.pathname} exact>
                                        <form className="text-center h-full flex flex-col items-center py-2 " onSubmit={loginSubmit}>
                                            <AppInput type={InputType.text} name="username" label="Username"></AppInput>
                                            <div className='flex w-full'>
                                                <AppInput className='inline-block' type={pwdVisible} name="password" label="Password" /> {pwdVisible == InputType.password ? <AiFillEye className='mt-6 ml-64 inline-block absolute text-alis-2' onClick={() => pwdVisibility()} size={20} /> : <AiFillEyeInvisible className='mt-6 ml-64 inline-block absolute text-alis-2' onClick={() => pwdVisibility()} size={20} />}
                                            </div>
                                            <div className="grid grid-cols-2 gap-2 pb-2">

                                                <div className="text-xs text-alis-2" >
                                                    <AppSwitch name="remember" label="Remember me" ></AppSwitch>

                                                </div>
                                                <div>
                                                    <Link className="my-2 text-alis-2 text-xs" to={`${location.pathname}/forgot`}>Forgot Password?</Link>
                                                </div>
                                            </div>

                                            <button className={`w-full my-2 py-2 text-white ${colors.mainGradient} ${colors.hoverGradient} select-none`}>LOGIN</button>
                                        </form>
                                    </Route>
                                    <Route path={`${location.pathname}/forgot`} >
                                        <form className="text-center h-full flex flex-col items-center py-2" onSubmit={forgotSubmit}>
                                            <AppInput type={InputType.email} name="email" label="Email" value='' />
                                            <button className={`w-full py-2 text-white ${colors.mainGradient} ${colors.hoverGradient}`}>CONTINUE</button>

                                            <Link className="my-2 text-alis-2" to={location.pathname}>Go back</Link>
                                        </form>
                                    </Route>
                                </Switch>
                            </Router>
                        </div>

                        <div className="p-4 grid grid-cols-1 items-center justify-items-center">
                            <a href='https://holis-consulting.com/' target="_blank">
                                <img src={holisLogoNew} className="object-contain h-9 w-9" alt="Holis Logo" />
                            </a>
                            <a href='https://holis-consulting.com/' target="_blank">
                                <i><h1 className='text-sm font-light text-gray-500 opacity-70'>Powered by HOLIS</h1></i>
                            </a>
                        </div>

                    </Loader>
                </div>
            </div>
        </div>
    )
}

export default Login
