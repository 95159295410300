import { Component } from 'react';
import { FaFileDownload } from 'react-icons/fa';

interface IProps {
  clicked: (data: any) => void
  text?: string
  data?: any
}

interface IState {

}

export default class BtnCellRenderer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    this.props.clicked(this.props.data);
  }
  render() {
    return (
      <FaFileDownload size={30} className=' pt-1 pr-2 text-gray-500 hover:text-gray-900' onClick={this.btnClickedHandler}></FaFileDownload>
    )
  }
}