import { Component } from 'react';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ColumnApi, GridApi, CellEvent } from "ag-grid-community";

import { Dropdown as DropdownRenderer, Details as DetailsRenderer, Button as ButtonRenderer, Comment as CommentRenderer } from '@components/grids/renderers'

import notifications, { add, loading } from "@services/NotificationService";

import API from '@services/ApiService';
import { Partial, ISystem, IUser, IProject, IItem } from '@utils/types'

import ContextMenu from '@components/contextmenu'

import { contextMenu } from 'react-contexify';
import "react-contexify/dist/ReactContexify.css";

import { User } from '@app/services/UserService';

import { BiCube, BiWebcam } from 'react-icons/bi'
import { IoIosPaper } from 'react-icons/io'

import ModalSystem from '@components/modal/ModalSystem'
import ModalComment from '@components/modal/ModalComment'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

interface IGridProps {
    project_id: string
    addSystemToBasket?: any
    removeSystemFromBasket?: any
}

interface IGridState {
    data?: ISystem[]
    users?: IUser[]
    show?: boolean
    showComment?: boolean
    modalSystem?: ISystem
    actualProject?: IProject
    admins?: IUser[]

    numberOfRow?: number
    numberOfSelectedRow?: number

    fields: { field: string, hide: boolean }[]

    preparation?: string
    overview?: string
    isometrics?: string
}

export default class SystemGrid extends Component<IGridProps, IGridState> {

    gridApi?: GridApi
    columnApi?: ColumnApi
    preparation?: string;
    overview?: string;
    isometrics?: string

    constructor(props: IGridProps) {
        super(props)

        this.state = {
            data: [],
            users: [],
            admins: [],
            preparation: "",
            overview: "",
            isometrics: "",
            show: false,
            showComment: false,
            numberOfRow: 0,
            numberOfSelectedRow: 0,

            fields: [],
            // panelOpen: false
        }

        //Call the function that retrieves all the users of the app
        this.getAppUsers();
        //Call the function that retrieves all the systems of the current project
        this.getSystems();
        //Call the function that retrieves the actual project
        this.getActualProject();

    }

    getMenu() {

        const menu: any[] = [{
            type: 'sub',
            text: 'Generate Overview',
            children: [
                {
                    type: 'item',
                    text: "Generate DWG",
                    onClick: this.generateOverviews.bind(this)
                },
                {
                    type: 'item',
                    text: "Generate PDF",
                    onClick: this.generateOverviewsPdf.bind(this)
                }]
        }]

        if (User.isAdmin) {

            const status = (column: String) => [
                {
                    type: 'item',
                    text: 'N/A',
                    onClick: this.updateSystems.bind(this, "N/A", column)
                },
                {
                    type: 'item',
                    text: 'HOLD',
                    onClick: this.updateSystems.bind(this, "HOLD", column)
                },
                {
                    type: 'item',
                    text: 'INITIATED',
                    onClick: this.updateSystems.bind(this, "INITIATED", column)
                },
                {
                    type: 'item',
                    text: 'PROCESSING',
                    onClick: this.updateSystems.bind(this, "PROCESSING", column)
                },
                {
                    type: 'item',
                    text: 'TO CHECK',
                    onClick: this.updateSystems.bind(this, "TO CHECK", column)
                },
                {
                    type: 'item',
                    text: 'TO APPROVE',
                    onClick: this.updateSystems.bind(this, "TO APPROVE", column)
                },
                {
                    type: 'item',
                    text: 'DONE',
                    onClick: this.updateSystems.bind(this, "DONE", column)
                },
            ]

            menu.push({
                type: 'sub',
                text: 'Generate Items',
                children: [
                    {
                        type: 'item',
                        text: "Generate DWG",
                        onClick: this.generateDrawings.bind(this)
                    },
                    {
                        type: 'item',
                        text: "Generate PDF",
                        onClick: this.generateDrawingsPdf.bind(this)
                    }]
            })

            menu.push({
                type: 'sub',
                text: 'Assign to',
                children: this.state.users?.map((user) => {
                    return {
                        type: 'item',
                        text: user.username,
                        onClick: () => this.assignToUser(user)
                    }
                })
            })

            menu.push({
                type: 'item',
                text: 'Unassign',
                onClick: this.unassign.bind(this)
            })

            menu.push({
                type: 'sub',
                text: 'Update status',
                children: [
                    {
                        type: 'sub',
                        text: "3D Preparation",
                        children: status("3DPREPARATION")
                    },
                    {
                        type: 'sub',
                        text: "Overview",
                        children: status("OVERVIEW")
                    },
                    {
                        type: 'sub',
                        text: "Isometrics",
                        children: status("ISOMETRICS")
                    }
                ]
            })
        }
        return menu
    }

    contextMenu(data: CellEvent) {

        if (!data.node.isSelected()) data.node.setSelected(true, true)

        contextMenu.show({
            id: 'SYS_CTX',
            event: data.event as any
        });
    }

    async getActualProject() {
        const response = await API.get(`/projects/${this.props.project_id}`)
        if (response.status === 200) {
            const actualProject = response.data
            this.setState({ actualProject })
        }
    }

    async generateOverviews() {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        loading.setState(true)
        const response = await API.post(`/projects/${this.props.project_id}/systems/overviews`, tags)
        if (response.status === 200) {
            add({
                severity: 'success',
                message: response.data
            }, 2000)
        }
        loading.setState(false)
    }

    async generateOverviewsPdf() {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        loading.setState(true)
        const response = await API.post(`/projects/${this.props.project_id}/systems/overviews/pdf`, tags)
        if (response.status === 200) {
            add({
                severity: 'success',
                message: response.data
            }, 2000)
        }
        loading.setState(false)
    }

    async generateDrawings() {

        try {
            if (!this.gridApi) return []
            let selectedNodes = this.gridApi.getSelectedNodes();
            const tags = selectedNodes.map(node => node.data.tag);

            loading.setState(true)
            const response = await API.post(`/projects/${this.props.project_id}/systems/items/drawings`, tags)
            if (response.status === 200) {
                add({
                    severity: 'success',
                    message: 'Drawings generated'
                }, 2000)
            }
            loading.setState(false)
        }
        catch (error) {

        }
    }

    async generateDrawingsPdf() {

        try {

            if (!this.gridApi) return []
            let selectedNodes = this.gridApi.getSelectedNodes();
            const tags = selectedNodes.map(node => node.data.tag);

            loading.setState(true)
            const response = await API.post(`/projects/${this.props.project_id}/systems/items/drawings/pdf`, tags)
            if (response.status === 200) {
                add({
                    severity: 'success',
                    message: 'Drawings generated'
                }, 2000)
            }
            loading.setState(false)
        }
        catch (error) {

        }
    }

    //Assign a system to an user
    async assignToUser(user: IUser) {

        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        const changingProperties = tags.map(tag => {

            return {
                tag: tag,
                user: user.username
            }
        })

        const response = await API.patch(`/projects/${this.props.project_id}/systems/`, changingProperties)
        if (response.status === 200 || response.status === 201) {

            const data = response.data

            for (const i in selectedNodes) {
                selectedNodes[i].setData(data[i])
                if (changingProperties[0].user == User.username) {
                    this.props.addSystemToBasket()
                }
            }
        }

        notifications.add({
            severity: 'success',
            message: 'Systems affected to user ' + user.username
        }, 2000)
    }

    async unassign() {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        const changingProperties = tags.map(tag => {
            return {
                tag: tag,
                user: null,
            }
        })

        const response = await API.patch(`/projects/${this.props.project_id}/systems/`, changingProperties)
        if (response.status === 200 || response.status === 201) {

            for (const i in selectedNodes) {
                if (selectedNodes[i].data.user == User.username) {
                    this.props.removeSystemFromBasket()
                }
                selectedNodes[i].setData(response.data[i])
            }

            add({
                severity: 'success',
                message: 'Systems now unassigned'
            }, 2000)
        }
    }

    //Determine the percentage of completion from system's 3D preparations
    percentageCalc3D() {
        if (this.state.data == null) return

        let count = 0;
        //Check if attribute is finished from all the systems of the project
        this.state.data.forEach(system => {
            if (system.navisworks_status == "DONE") count++
        })

        let percentageCompleted = count * 100;
        percentageCompleted = percentageCompleted / this.state.data.length

        return percentageCompleted.toFixed(0);
    }

    //Determine the percentage of completion from system's overviews
    percentageCalcOverview() {
        if (this.state.data == null) return

        let count = 0;
        //Check if attribute is finished from all the systems of the project
        this.state.data.forEach(system => {
            if (system.overview_status == "DONE") count++
        })

        let percentageCompleted = count * 100;
        percentageCompleted = percentageCompleted / this.state.data.length

        return percentageCompleted.toFixed(0);
    }

    //Determine the percentage of completion from system's isometrics
    percentageCalcIsometrics() {
        if (this.state.data == null) return

        let count = 0;
        //Check if attribute is finished from all the systems of the project
        this.state.data.forEach(system => {
            if (system.isometrics_status == "DONE") count++
        })

        let percentageCompleted = count * 100;
        percentageCompleted = percentageCompleted / this.state.data.length

        return percentageCompleted.toFixed(0);
    }

    onSelectionChanged = (event: any) => {
        var rowCount = event.api.getSelectedNodes().length;
        this.setState({ numberOfSelectedRow: rowCount })
    };

    render() {

        //Set the headers titles with dynamic percentages of completion
        this.preparation = `3D PREPARATION : ${this.percentageCalc3D()} %`
        this.overview = `OVERVIEW : ${this.percentageCalcOverview()} %`
        this.isometrics = `ISOMETRICS : ${this.percentageCalcIsometrics()} %`

        const defaultColDef = {
            sortable: true,
            resizable: true,
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            toolPanel: 'columns',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] },
            cellStyle: { fontSize: '11px' }
        }

        const menu = this.getMenu()

        return (

            <div className="ag-theme-material h-full w-full relative flex flex-col">

                <div className='columns-2 pt-6 pb-6 align-middle relative pl-4 bg-white'>
                    <h2 className='text-alis-2 font-medium ml-2 text-sm'>{this.state.actualProject?.name}</h2>
                    <div className='text-black'>
                        <h1 className='font-bold ml-2 text-base'>SYSTEM REGISTER</h1>

                        <div className='flex flex-row items-start space-x-2 font-semibold relative text-sm'>
                            <BiCube className='w-5 h-5'></BiCube> <h1>{this.preparation}</h1>
                            <BiWebcam className='w-5 h-5'></BiWebcam> <h1>{this.overview}</h1>
                            <IoIosPaper className='w-5 h-5'></IoIosPaper> <h1>{this.isometrics}</h1>
                        </div>
                    </div>
                </div>

                {(this.state.modalSystem && this.state.show == true) &&
                    <ModalSystem
                        onClose={() => this.setState({ show: false })}
                        system={this.state.modalSystem!}
                        project={this.state.actualProject!}
                    />}
                {(this.state.modalSystem && this.state.showComment == true) &&
                    <ModalComment
                        onClose={(data) => {
                            this.setState({ showComment: false });
                            if (data) this.forceUpdate()
                        }}
                        type="systems"
                        data={this.state.modalSystem!}
                        user={User}
                        project_id={this.props.project_id}
                    />}

                <ContextMenu menu={menu} MENU_ID='SYS_CTX'></ContextMenu>

                <AgGridReact
                    rowData={this.state.data}
                    defaultColDef={defaultColDef}
                    suppressDragLeaveHidesColumns={true}
                    rowSelection='multiple'
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    getRowStyle={(params: any) => {
                        let backgroundColor = ''
                        if (params.data.user != null) backgroundColor = '#E0E0E0'
                        return { backgroundColor }
                    }}
                    frameworkComponents={{
                        DropdownRenderer,
                        DetailsRenderer,
                        ButtonRenderer,
                        CommentRenderer
                    }}
                    getRowNodeId={(data: any) => {
                        return data.tag;
                    }}
                    onGridReady={this.onGridReady.bind(this)}
                    onFirstDataRendered={this.onGridReady.bind(this)}
                    onCellContextMenu={this.contextMenu}
                    preventDefaultOnContextMenu={true}
                    onColumnVisible={this.saveState.bind(this)}
                    onColumnPinned={this.saveState.bind(this)}
                    onColumnResized={this.saveState.bind(this)}
                    onDragStopped={this.saveState.bind(this)}
                    onSortChanged={this.saveState.bind(this)}
                    onColumnValueChanged={this.saveState.bind(this)} // A value column was added or removed.
                    onFilterChanged={this.saveFilter.bind(this)}
                >

                    <AgGridColumn resizable={false} maxWidth={50} suppressFiltersToolPanel={true} sortable={false}
                        cellRenderer="DetailsRenderer" cellRendererParams={{
                            clicked: (data: ISystem) => {
                                this.setState({
                                    show: true,
                                    modalSystem: data
                                });
                            }
                        }} />
                    <AgGridColumn field="site" headerName="SITE" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="sector" headerName="SECTOR" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="tag" headerName="TAG" floatingFilter={true} />
                    <AgGridColumn field="technical_class.class.name" headerName="CLASS" floatingFilter={true} />
                    <AgGridColumn field="technicalClassName" headerName="TECHNICAL CLASS" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="description" headerName="DESCRIPTION" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="inspection_type" headerName="INSPECTION TYPE"
                        floatingFilter={true}
                        maxWidth={150}
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'ISO',
                                'P&ID',
                                '-'
                            ],
                            onChange: (system: ISystem, value: string) => {
                                this.setInspectionType(system, { inspection_type: value })
                            }
                        }} />
                    <AgGridColumn field="scheduling_tag" headerName="SCHEDULING TAG" floatingFilter={true} />
                    <AgGridColumn field="navisworks_status" floatingFilter={true}
                        headerName="3D PREPARATION"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'HOLD',
                                'INITIATED',
                                'PROCESSING',
                                'TO CHECK',
                                'TO APPROVE',
                                'DONE',
                                'N/A'
                            ],
                            onChange: (system: ISystem, value: string) => {
                                //this.preparation = `3D PREPARATION : ${this.percentageCalc3D()} %`
                                this.updateSystem(system, { navisworks_status: value });
                            }
                        }}
                        minWidth={170}
                        resizable={false} />
                    <AgGridColumn field="overview_status"
                        floatingFilter={true}
                        headerName="OVERVIEW"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            disabled: false,
                            values: [
                                'HOLD',
                                'INITIATED',
                                'PROCESSING',
                                'TO CHECK',
                                'TO APPROVE',
                                'DONE',
                                'N/A'
                            ],
                            onChange: (system: ISystem, value: string) => {
                                this.updateSystem(system, { overview_status: value });
                            }
                        }}
                        minWidth={160}
                        resizable={false} />
                    <AgGridColumn field="isometrics_status"
                        floatingFilter={true}
                        headerName="ISOMETRICS"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'HOLD',
                                'INITIATED',
                                'PROCESSING',
                                'TO CHECK',
                                'TO APPROVE',
                                'DONE',
                                'N/A'
                            ],
                            onChange: (system: ISystem, value: string) => {
                                this.updateSystem(system, { isometrics_status: value });
                            }
                        }}
                        minWidth={160}
                        resizable={false} />
                    <AgGridColumn resizable={false} maxWidth={50} suppressFiltersToolPanel={true} sortable={false}
                        cellRenderer="CommentRenderer"
                        cellRendererParams={{
                            onClick: (data: ISystem) => {
                                this.setState({
                                    showComment: true,
                                    modalSystem: data
                                });
                            }
                        }}
                        field="comment"
                        headerName="COMMENT" />
                    <AgGridColumn field="overviewName" headerName="OVERVIEW NAME" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn
                        floatingFilter={true}
                        editable={User.isAdmin}
                        field="due_date" headerName="DUE DATE"
                        filter="agDateColumnFilter"
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }}
                        cellRenderer={(data: any) => {
                            return data.value ? (new Date(data.value)).toLocaleDateString() : '';
                        }}
                    />
                    <AgGridColumn field="user" headerName="ATTRIBUTED USER" floatingFilter={true} />
                    <AgGridColumn field="inspection_strategie" headerName="INSPECTION STRATEGIE" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="cof" headerName="COF" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="process_function" headerName="PROCESS FUNCTION" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="corrosion_loop" headerName="CORROSION LOOP" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                </AgGridReact>

                <div className='bg-white h-10'>
                    <div className='flex'>
                        <h1 className='m-2 text-sm font-semibold text-gray-300'>Total : {this.state.numberOfRow}</h1>
                        <h1 className='m-2 text-sm font-bold text-gray-400'>Rows selected : {this.state.numberOfSelectedRow}</h1>
                    </div>
                </div>
            </div>
        );
    }

    autoSizeAll(skipHeader: boolean = false): void {
        if (this.columnApi) {
            const allColumnIds: string[] = [];
            const columns = this.columnApi.getAllColumns()
            if (columns) columns.forEach((column: any) => {
                allColumnIds.push(column.getId());
            });
            this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    }

    onGridReady(params: GridReadyEvent): void {
        // or setState if using components
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.columnApi.autoSizeAllColumns(false)
        // this.gridApi.setFilterModel(null)

        const numberOfRow = this.gridApi.getDisplayedRowCount()
        this.setState({ numberOfRow })

        this.restoreState()
    }

    async getSystems() {
        try {
            const response = await API.get(`/projects/${this.props.project_id}/systems`)
            if (response.status !== 200) return

            const data = response.data
            this.setState({ data })

        } catch (error) {
            add({
                message: 'Failed to get project systems!',
                severity: "error"
            }, 2000)
        }
    }

    async getAppUsers() {
        try {
            const response = await API.get('/projects/' + this.props.project_id + '/users')
            const users = response.data;

            const admins = users.filter((user: IUser) => user.isAdmin)
            this.setState({ users, admins })
        } catch (error) {
            add({
                message: 'Failed to get project users!',
                severity: "error"
            }, 2000)
        }
    }

    async updateSystem(system: ISystem, patch_data: Partial<ISystem>) {
        try {

            const response = await API.patch(`/projects/${this.props.project_id}/systems/${system.tag}`, patch_data)
            if (response.status === 200 || response.status === 201) {

                const rowNode = this.gridApi?.getRowNode(system.tag);
                rowNode?.setData({ ...rowNode?.data, ...patch_data });
            }

            return response
        } catch (error) {
            add({
                message: `Failed to update project system id: ${system.tag}!`,
                severity: "error"
            }, 2000)
        }
    }

    async setInspectionType(system: ISystem, patch_data: Partial<ISystem>) {

        if (patch_data.inspection_type === "-") {
            patch_data.navisworks_status = "N/A"
            patch_data.overview_status = "N/A"
            patch_data.isometrics_status = "N/A"

            const response = await this.updateSystem(system, patch_data)
            if (!response || response.status !== 200) return

            try {

                const responseItems = await API.get(`/projects/${this.props.project_id}/systems/${system.tag}/items`)

                if (responseItems.status !== 200) throw new Error()

                responseItems.data.map((item: IItem) => (
                    item.status = "N/A",
                    item.inspection_type = "-"
                ))

                const responseUpdateItems = await API.put(`/projects/${this.props.project_id}/systems/${system.tag}/items`, responseItems.data)
                if (responseUpdateItems.status !== 200) throw new Error()
            } catch (error) {
                add({
                    message: `Failed to update items of system id: ${system.tag}!`,
                    severity: "error"
                })
            }
        } else {
            if (patch_data.inspection_type == "P&ID") patch_data.navisworks_status = "N/A"
            this.updateSystem(system, patch_data)
        }
    }

    async updateSystems(value: String, column: String) {

        try {

            if (!this.gridApi) return []
            let selectedNodes = this.gridApi.getSelectedNodes();
            const tags = selectedNodes.map(node => node.data.tag);

            let changingProperties;

            if (column == "3DPREPARATION") {
                changingProperties = tags.map(tag => {
                    return {
                        tag: tag,
                        navisworks_status: value,
                    }
                })
            }

            else if (column == "OVERVIEW") {
                changingProperties = tags.map(tag => {
                    return {
                        tag: tag,
                        overview_status: value,
                    }
                })
            }

            else if (column == "ISOMETRICS") {
                changingProperties = tags.map(tag => {
                    return {
                        tag: tag,
                        isometrics_status: value,
                    }
                })
            }

            const response = await API.patch(`/projects/${this.props.project_id}/systems/`, changingProperties)
            if (response.status === 200 || response.status === 201) {

                for (const i in selectedNodes) {
                    selectedNodes[i].setData(response.data[i])
                }
            }

        } catch (error) {
            add({
                message: `Failed to update projects`,
                severity: "error"
            }, 2000)
        }
    }

    saveFilter() {
        if (!this.gridApi) return;
        let filter = this.gridApi.getFilterModel();
        localStorage.setItem('systemTableFilter', JSON.stringify(filter));
    }
    saveState() {
        if (!this.columnApi) return;
        let state = this.columnApi?.getColumnState();
        localStorage.setItem('systemTableState', JSON.stringify(state));
    }

    restoreState() {
        //STATE
        let data = localStorage.getItem('systemTableState')
        if (data) {
            let state = JSON.parse(data)
            this.columnApi?.applyColumnState({ state })
        }
    }
}
