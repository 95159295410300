import { ChangeEvent, Component } from 'react';

import { IDictionary } from '@utils/types'
import { User } from '@app/services/UserService';

interface IProps {
  onChange: (data: any, value: string) => void
  data: any
  value: string
  valueFormatted?: string
  [key: string]: any
}

export default class Dropdown extends Component<IProps, any> {

  colors: IDictionary<string> = {
    'N/A': "#7f7f7f",
    'HOLD': '#f2f2f2',
    'INITIATED': '#ffbd49',
    'PROCESSING': '#ffe53b',
    'TO CHECK': '#50c6ff',
    'TO APPROVE': '#dc96ff',
    'DONE': '#98FB98',

    'ISO': '#fff',
    'P&ID': '#fff'
  }

  fontColors: IDictionary<string> = {
    'N/A': "#000000",
    'HOLD': '#7f7f7f',
    'INITIATED': '#c55a11',
    'PROCESSING': '#968100',
    'TO CHECK': '#2e75b6',
    'TO APPROVE': '#9900cc',
    'DONE': '#548235',

    'P&ID' : '#2BD2FF',
    'ISO' : '#FA8BFF'
  }

  changeHandler(e: ChangeEvent<HTMLSelectElement>) {
    if (this.props.onChange) this.props.onChange(this.props.data, e.target.value);
  }

  render() {
    const value = this.props.valueFormatted ? this.props.valueFormatted : this.props.value

    let disabled = (this.props.data.user != User.username && User.isAdmin != true && (!this.props.data.system || this.props.data.system.user != User.username))

    return (
        <select disabled={disabled} className="rounded-full w-full text-center p-1 opacity-90" style={{ backgroundColor: this.colors[value], color: this.fontColors[value] }} value={value} onChange={this.changeHandler.bind(this)}>
          {this.props.values.map((v: string, i: number) => <option key={i} value={v} >{v}</option>)}
        </select>
    )
  }
}