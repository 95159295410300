import axios, {AxiosResponse} from 'axios';

import notifications from '@services/NotificationService'
import { USER_STATE } from '@services/UserService'


export const BASE_URL = process.env.REACT_APP_API_ENDPOINT.toString();

const API = axios.create({
  baseURL: BASE_URL
});
export const requestInterceptor = API.interceptors.request.use((config) => {
  // Do something before request is sent
  // if(!config.headers.authorization) config.headers.authorization = 'Bearer ' + user.properties.token
  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

export const responseInterceptor = API.interceptors.response.use(
  (response: AxiosResponse)  => {
    return response
  },
  error => {

    if (!error.response.status) {
      notifications.add({
        message: 'Please check your internet connection.',
        severity: 'warning'
      })
    } else if(error.response.status === 401){
      USER_STATE.setState(null)
    }else {
      notifications.add({
        message: error.response.data?.error,
        severity: 'warning'
      })
    }

    return Promise.reject(error.response)

  }
)

export default API