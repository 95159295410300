import { Component, HTMLAttributes } from 'react'
import { FiMenu as MenuIcon } from 'react-icons/fi'


interface ISideDrawerProps extends HTMLAttributes<HTMLDivElement> {

}

interface ISideDrawerStates {
  open: boolean
}

export default class SideDrawer extends Component<ISideDrawerProps, ISideDrawerStates> {
  constructor(props: ISideDrawerProps) {
    super(props)
    this.state = {
      open: true
    }
  }

  render() {
    return (
      <div className="relative h-full">
        <div className={
          "z-10 absolute flex flex-col h-full transition-all ease-out duration-500 transform"
          + (this.state.open ? ' translate-x-0' : ' -translate-x-full')
        }>
          <div className="flex flex-shrink h-12 bg-gray-500 bg-opacity-50">
            <div className="flex-grow"></div>
            <div className="flex items-center px-2">
              <MenuIcon className={[
                "w-7",
                "h-7",
                "text-gray-50",
                "hover:text-gray-700",
                "bg-gray-700",
                "hover:bg-gray-50",
                "rounded-sm",
                "cursor-pointer",
                "transition-all",
                "ease-out",
                "duration-500",
                "transform",
                ((this.state.open ? ' translate-x-0' : ' translate-x-12'))
              ].join(' ')}
                onClick={() => this.setState((state, props) => ({
                  open: !state.open
                }))}
              />
            </div>
          </div>
          <div className="flex-grow bg-gray-800 bg-opacity-90 overflow-hidden">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }

}
