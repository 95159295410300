import { Component } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AgGridEvent, CellEvent, ColumnApi, GridApi } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import DropdownRenderer from './renderers/Dropdown'
import CommentRenderer from './renderers/Comment'
import DetailsRenderer from '@components/grids/renderers/DetailsItem'

import { add, loading } from "@services/NotificationService";
import { User } from '@app/services/UserService';

import ContextMenu from '@components/contextmenu'
import { contextMenu } from 'react-contexify';

import API from '@services/ApiService';
import { Partial, IItem, ISystem } from '@utils/types'
import ModalComment from '@components/modal/ModalComment';
import ModalPdf from '../modal/ModalPdf';

interface IGridProps {
    project_id: string
    system: ISystem
}

interface IGridState {
    modalItem?: IItem
    showComment?: boolean
    items?: IItem[]
    showDrawingPdf?: boolean

    numberOfRow?: number
    numberOfSelectedRow?: number
}

export default class Grid extends Component<IGridProps, IGridState> {

    gridApi?: GridApi
    columnApi?: ColumnApi

    constructor(props: IGridProps) {
        super(props)

        this.state = {
            modalItem: undefined,
            showComment: false,
            items: undefined,
            showDrawingPdf: false,

            numberOfRow: 0,
            numberOfSelectedRow: 0
        }

        this.getItems(this.props.system)
    }

    //Retrieve all the items contained in a system
    async getItems(system: ISystem) {
        const response = await API.get(`/projects/${this.props.project_id}/systems/${system.tag}/items`)
        if (response.status === 200 || response.status === 201) {
            const items = response.data
            this.setState({ items })
        }
    }

    async getCurrentItem(item: IItem) {
        const response = await API.get(`/projects/${this.props.project_id}/items/${item.tag}`)

        if (response.status === 200 || response.status === 201) {
            this.setState({ modalItem: item })
        }
    }

    async generateDrawings() {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        loading.setState(true)
        const response = await API.post(`/projects/${this.props.project_id}/items/drawings`, tags)
        if (response.status === 200) {
            add({
                severity: 'success',
                message: response.data
            }, 2000)
        }
        loading.setState(false)
    }

    async generateDrawingsPdf() {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        loading.setState(true)
        const response = await API.post(`/projects/${this.props.project_id}/items/drawings/pdf`, tags)
        if (response.status === 200) {
            add({
                severity: 'success',
                message: response.data
            }, 2000)
        }
        loading.setState(false)
    }

    contextMenuItem(data: CellEvent) {

        if (!data.node.isSelected()) data.node.setSelected(true, true)

        contextMenu.show({
            id: 'IT_CTX',
            event: data.event as any
        });
    }

    getMenu() {

        const status = () => [
            {
                type: 'item',
                text: 'N/A',
                onClick: this.updateItems.bind(this, "N/A")
            },
            {
                type: 'item',
                text: 'HOLD',
                onClick: this.updateItems.bind(this, "HOLD")
            },
            {
                type: 'item',
                text: 'DONE',
                onClick: this.updateItems.bind(this, "DONE")
            },
        ]

        const menu: any[] = [{
            type: 'sub',
            text: 'Generate Drawing',
            children: [{
                type: 'item',
                text: 'Generate DWG',
                onClick: this.generateDrawings.bind(this)
            },
            {
                type: 'item',
                text: 'Generate PDF',
                onClick: this.generateDrawingsPdf.bind(this)
            }]
        },
        {
            type: 'sub',
            text: 'Update status',
            children: status()
        }]
        return menu
    }

    onSelectionChanged = (event: any) => {
        var rowCount = event.api.getSelectedNodes().length;
        this.setState({ numberOfSelectedRow: rowCount })
    };

    render() {
        const defaultColDef = {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] },
            cellStyle: { fontSize: '11px' }
        };

        const menu = this.getMenu()

        return (
            <div className="ag-theme-material h-full w-full" >

                {(this.state.modalItem && this.state.showComment == true) &&
                    <ModalComment type="items"
                        onClose={(data) => {
                            this.setState({ showComment: false });
                            if (data) this.forceUpdate()
                        }}
                        data={this.state.modalItem!}
                        user={User}
                        project_id={this.props.project_id} />}

                {(this.state.showDrawingPdf) &&
                    <ModalPdf hideModal={() => {
                        this.setState({ showDrawingPdf: false });
                    }}
                        docType="DWG"
                        project_id={this.props.project_id}
                        tag={this.state.modalItem?.tag} />}

                <ContextMenu menu={menu} MENU_ID='IT_CTX'></ContextMenu>
                <AgGridReact
                    rowData={this.state.items}
                    defaultColDef={defaultColDef}
                    suppressDragLeaveHidesColumns={true}
                    rowSelection={'multiple'}
                    frameworkComponents={{
                        DetailsRenderer,
                        DropdownRenderer,
                        CommentRenderer
                    }}
                    getRowNodeId={(data) => {
                        return data.tag;
                    }}
                    onFirstDataRendered={this.onGridReady.bind(this)}
                    onCellContextMenu={this.contextMenuItem}
                    preventDefaultOnContextMenu={true}
                    onColumnVisible={this.saveState.bind(this)}
                    onColumnPinned={this.saveState.bind(this)}
                    onColumnResized={this.saveState.bind(this)}
                    onDragStopped={this.saveState.bind(this)}
                    onSortChanged={this.saveState.bind(this)}
                    onColumnValueChanged={this.saveState.bind(this)} // A value column was added or removed.
                    onFilterChanged={this.saveFilter.bind(this)}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                >
                    <AgGridColumn resizable={false} maxWidth={50} suppressFiltersToolPanel={true} sortable={false}
                        cellRenderer="DetailsRenderer" cellRendererParams={{
                            project_id: this.props.project_id,
                            clicked: (data: IItem) => {
                                this.setState({
                                    showDrawingPdf: true,
                                    modalItem: data
                                });
                            }
                        }} />
                    <AgGridColumn field="status" headerName="STATUS"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'HOLD',
                                'DONE',
                                'N/A'
                            ],
                            onChange: (item: IItem, value: string) => {
                                this.updateItem(item, { status: value })
                            }
                        }}
                        minWidth={160}
                        resizable={false} />
                    <AgGridColumn field="tag" headerName='TAG' />
                    <AgGridColumn field="system.technical_class.class.name" headerName="CLASS" />
                    <AgGridColumn field="system.technicalClassName" headerName="TECHNICAL CLASS" />
                    <AgGridColumn resizable={false} maxWidth={50} suppressFiltersToolPanel={true} sortable={false}
                        cellRenderer="CommentRenderer" cellRendererParams={{
                            onClick: (data: IItem) => {
                                this.setState({
                                    showComment: true,
                                    modalItem: data
                                });
                            }
                        }} field="comment" headerName="COMMENT" />
                    <AgGridColumn field="systemTag" headerName="SYSTEM TAG" />
                    <AgGridColumn field="scheduling_tag" headerName="SCHEDULING TAG" />
                    <AgGridColumn field="pid1" headerName="PID 1" />
                    <AgGridColumn field="inspection_type" headerName="INSPECTION TYPE"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'ISO',
                                'P&ID',
                                '-',
                            ],
                            onChange: (item: IItem, value: string) => {
                                this.updateItem(item, { inspection_type: value })
                            }
                        }}
                        width={150}
                        resizable={false} />
                    <AgGridColumn field="drawingName" headerName="DRAWING NAME" editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var item: IItem = params.data
                            this.updateItem(item, item)
                        }} />
                </AgGridReact>

                <div className='bg-white h-10'>
                    <div className='flex justify-between'>
                        <h1 className='m-2 text-sm font-bold text-gray-400'>Rows selected : {this.state.numberOfSelectedRow}</h1>
                        <h1 className='m-2 text-sm font-bold text-gray-400'>Total : {this.state.numberOfRow}</h1>
                    </div>
                </div>
            </div>
        );
    }

    autoSizeAll(skipHeader: boolean = false): void {
        if (this.columnApi) {
            const allColumnIds: string[] = [];
            const columns = this.columnApi.getAllColumns()
            if (columns) columns.forEach((column) => {
                allColumnIds.push(column.getId())
            });
            this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    }

    onGridReady(params: AgGridEvent): void {
        // or setState if using components
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.autoSizeAll(true)

        const numberOfRow = this.gridApi.getDisplayedRowCount()
        this.setState({ numberOfRow })

        this.restoreState()
    }

    saveState() {
        if (!this.columnApi) return;
        let state = this.columnApi?.getColumnState();
        localStorage.setItem('itemTableState', JSON.stringify(state));
    }

    saveFilter() {
        if (!this.gridApi) return;
        let filter = this.gridApi.getFilterModel();
        localStorage.setItem('itemTableFilter', JSON.stringify(filter));
    }

    restoreState() {
        //STATE
        let data = localStorage.getItem('itemTableState')
        if (data) {
            let state = JSON.parse(data)
            this.columnApi?.applyColumnState({ state })
        }
    }

    async updateItem(item: IItem, patch_data: Partial<IItem>) {
        try {
            const response = await API.patch(`/projects/${this.props.project_id}/items/${item.tag}`, patch_data)
            if (response.status === 200) {
                const rowNode = this.gridApi?.getRowNode(item.tag);
                rowNode?.setData({ ...rowNode?.data, ...patch_data });
            }
        } catch (error) {
            add({
                message: `Failed to update project items id: ${item.tag}!`,
                severity: "error"
            }, 2000)
        }
    }

    async updateItems(value: String) {
        try {
            if (!this.gridApi) return []
            let selectedNodes = this.gridApi.getSelectedNodes();
            const tags = selectedNodes.map(node => node.data.tag);

            let changingProperties

            changingProperties = tags.map(tag => {
                return {
                    tag: tag,
                    status: value,
                }
            })

            const response = await API.patch(`/projects/${this.props.project_id}/items/`, changingProperties)
            if (response.status === 200 || response.status === 201) {

                for (const i in selectedNodes) {
                    selectedNodes[i].setData(response.data[i])
                }
            }

        } catch (error) {

        }
    }
}
