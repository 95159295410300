import { Component } from 'react'

import API from '@services/ApiService'
import { IDocument } from '@app/utils/types'
import Modal from './Modal'
import notifications from '@app/services/NotificationService'

interface IProps {
    hideModal: () => void
    doc?: IDocument
    project_id: string
    tag?: string
    docType?: string
}

interface IState {
    content: string | null
    isLoading: boolean
}

export default class ModalPdf extends Component<IProps, IState> {

    state: Readonly<IState> = {
        content: null,
        isLoading: true
    }

    getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    async getPdfContent() {
        try {

            let url
            if (this.props.docType == "OVW") url = `/projects/${this.props.project_id}/systems/${this.props.tag}/overviews/download`
            else if (this.props.docType == "DWG") url = `/projects/${this.props.project_id}/items/${this.props.tag}/drawings/download`
            else url = `/projects/${this.props.project_id}/documents/${this.props.doc!.id}/download`

            const response = await API.get(url, { responseType: 'arraybuffer' })
            if (response.status === 200 || response.status === 201) {

                this.setState({
                    content: URL.createObjectURL(new Blob([response.data], {
                        type: "application/pdf"
                    })),
                    isLoading: false
                })
            }
        }

        catch (error) {
            notifications.remove(0)
            this.setState({ isLoading: false })
        }

    }
    render() {

        if (this.state.isLoading) {
            return ""
        }

        return (
            <div>

                <Modal handleClose={this.props.hideModal}>

                    {this.state.content ?
                        <div className='relative self-center justify-self-center h-5/6 w-5/6 flex flex-col'>
                            <div className='bg-[#ededed] pb-4 pl-4 pt-4'>
                                <h1 className='text-alis-2 text-base font-bold position: static'>Document: </h1>
                                <h1 className='font-bold text-black'>{this.props.doc?.originalname}</h1>
                            </div>

                            <div className='flex-1'>
                                <embed src={this.state.content} className='h-full w-full' />
                            </div>
                        </div>
                        :
                        <div className='relative self-center justify-self-center h-1/6 w-1/6 flex flex-col'>
                            <div className='bg-[#ededed] pb-4 pl-4 pt-4'>
                                <h1 className='text-alis-2 text-base font-bold position: static'>Document: </h1>
                                <h1 className='font-bold text-black'>{this.props.doc?.originalname}</h1>
                            </div>

                            <div className='grid h-full w-full bg-white'>
                                <h1 className='text-black text-sm font-bold self-center text-center'>PDF not found</h1>
                            </div>
                        </div>
                    }
                </Modal>
            </div>
        )
    }
    componentDidMount() {
        this.getPdfContent()
    }
}
