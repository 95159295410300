import login from './Login'
import home from './Home'
import project from './project'
import admin from './Admin'

const pages = {
    login,
    home,
    project,
    admin
}
export default pages