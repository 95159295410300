import { add } from "@services/NotificationService";

import API from '@services/ApiService';
import { IUser } from '@utils/types'

import "react-contexify/dist/ReactContexify.css";

import { User } from '@app/services/UserService';

import Systems from './Systems';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

export default class Basket extends Systems {

    async getSystems() {
        try {
            const response = await API.get(`/projects/${this.props.project_id}/users/${User.id}/systems`)
            if (response.status === 200) {

                const data = response.data

                this.setState({ data })
            }
        } catch (error) {
            add({
                message: 'Failed to get project systems!',
                severity: "error"
            },2000)
        }
    }

    componentDidMount() {
        this.getSystems();
    }

    getMenu() {

        const menu: any[] = [{
            type: 'sub',
            text: 'Generate Overview',
            children: [
                {
                    type: 'item',
                    text: "Generate DWG",
                    onClick: this.generateOverviews.bind(this)
                },
                {
                    type: 'item',
                    text: "Generate PDF",
                    onClick: this.generateOverviews.bind(this)
                }]
        }]
        
        menu.push({
            type: 'sub',
            text: 'Generate items',
            children: [
                {
                    type: 'item',
                    text: "Generate DWG",
                    onClick: this.generateDrawings.bind(this)
                },
                {
                    type: 'item',
                    text: "Generate PDF",
                    onClick: this.generateDrawings.bind(this)
                }]
        })

        if (User.isAdmin) {

            const status = (column : String) => [
                {
                    type: 'item',
                    text: 'N/A',
                    onClick: this.updateSystems.bind(this, "N/A", column)
                },
                {
                    type: 'item',
                    text: 'HOLD',
                    onClick: this.updateSystems.bind(this, "HOLD", column)
                },
                {
                    type: 'item',
                    text: 'INITIATED',
                    onClick: this.updateSystems.bind(this, "INITIATED", column)
                },
                {
                    type: 'item',
                    text: 'PROCESSING',
                    onClick: this.updateSystems.bind(this, "PROCESSING", column)
                },
                {
                    type: 'item',
                    text: 'TO CHECK',
                    onClick: this.updateSystems.bind(this, "TO CHECK", column)
                },
                {
                    type: 'item',
                    text: 'TO APPROVE',
                    onClick: this.updateSystems.bind(this, "TO APPROVE", column)
                },
                {
                    type: 'item',
                    text: 'DONE',
                    onClick: this.updateSystems.bind(this, "DONE", column)
                },
            ]

            menu.push({
                type: 'sub',
                text: 'Assign to',
                children: this.state.users?.map((user) => {
                    return {
                        type: 'item',
                        text: user.username,
                        onClick: () => this.assignToUser(user)
                    }
                })
            })

            menu.push({
                type: 'item',
                text: 'Unassign',
                onClick: this.unassign.bind(this)
            })

            menu.push({
                type: 'sub',
                text: 'Update status',
                children: [
                    {
                        type: 'sub',
                        text: "3D Preparation",
                        children: status("3DPREPARATION")
                    },
                    {
                        type: 'sub',
                        text: "Overview",
                        children: status("OVERVIEW")
                    },
                    {
                        type: 'sub',
                        text: "Isometrics",
                        children: status("ISOMETRICS")
                    }
                ]
            })

        } else {
            menu.push({
                type: 'sub',
                text: 'Send',
                children: this.state.admins?.map((user) => {
                    return {
                        type: 'item',
                        text: user.username,
                        onClick: () => this.assignToAnAdmin(user)
                    }
                })
            })
        }
        return menu
    }

    async assignToAnAdmin(user: IUser) {

        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        const changingProperties = tags.map(tag => {
            return {
                tag: tag,
                user: user.username
            }
        })

        const response = await API.patch(`/projects/${this.props.project_id}/systems/`, changingProperties)
        if (response.status === 200 || response.status === 201) {
            for (const i in selectedNodes) {
                if (selectedNodes[i].data.user == User.username) {
                    this.props.removeSystemFromBasket()
                }
                selectedNodes[i].setData(response.data[i])
            }

            add({
                severity: 'success',
                message: 'Systems' + ' have been sent to ' + user.username
            },2000)

            this.getSystems()
        }
    }
}