import { createRef, Component } from 'react';
import Dropzone from 'react-dropzone'
import { FaCloudUploadAlt as UploadIcon } from 'react-icons/fa'

import { AiFillFile } from 'react-icons/ai'

interface IProps {
  uploadHandler?: (files: File[]) => void
  name?: string
  multiple?: boolean
  disabled?: boolean
  accept?: string | string[]
  minimized?: boolean
  associated?: boolean

  className? : string
}

interface IState {
  files: File[] | null

  bloc : string | null
  file : File | null
}
export default class Upload extends Component<IProps, IState> {

  inputRef

  constructor(props: any) {
    super(props);
    this.state = {
      files: null,
      bloc : "",
      file : null
    }
    this.inputRef = createRef<HTMLInputElement>()
  }

  uploadHandler(files: File[]) {

    this.setState({
      files
    })
    
    if (this.props.uploadHandler) this.props.uploadHandler(files)
  }

  render() {
    let content: {} | null | undefined = null
    let style = ""
    if(this.props.minimized){
      style = "h-10 w-full flex flex-col items-center bg-white hover:bg-gray-600 hover:text-white text-gray-600 ease-linear transition-all duration-150 shadow-md tracking-wide uppercase border border-blue cursor-pointer align-middle"
      content = this.props.associated ? 
        <AiFillFile className="w-10 h-10 inline-block p-1" /> : <UploadIcon className="w-10 h-10 inline-block p-1" />
      if(this.state.files) content = <span>{this.state.files.map(f => f.name).join(', ')}</span>
    }else{
      style = "h-full w-full flex flex-col items-center px-4 py-6 bg-white hover:bg-gray-600 hover:text-white text-gray-600 ease-linear transition-all duration-150 shadow-md tracking-wide uppercase border border-blue cursor-pointer align-middle"
      content = !this.state.files ?
        <><UploadIcon className="w-7 h-7 inline-block mr-2" />
        <span className="mt-2 text-base text-center leading-normal">Select a file<br />Or drag and drop</span>
        </>
        :
        <span>{this.state.files.map(f => f.name).join(', ')}</span>
    }

    return (
      <label onDrop={(e) => {
        if (this.inputRef.current) this.inputRef.current.files = e.dataTransfer.files
      }}>
          <Dropzone onDrop={this.uploadHandler.bind(this)} disabled={this.props.disabled} accept={this.props.accept}>
            {({ getRootProps, getInputProps }) => (
              <div className={style}
                {...getRootProps()}>
                {content}
                <input name={this.props.name} multiple={this.props.multiple} {...getInputProps()} ref={this.inputRef} />
              </div>
            )}
          </Dropzone>
      </label>
    )
  }
}