import { Component } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import '@styles/App.css'
import '@styles/scrollbar.css'

import pages from '@app/pages'

import { INotification } from '@app/utils/types'

import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import notifications from '@services/NotificationService'
import { USER_STATE, User, handshake } from '@services/UserService'

interface IAppProps {

}

interface IAppState {
  loading: boolean,
  isLogged?: boolean,
  urn: string,
  notifications: INotification[]
}

class App extends Component<IAppProps, IAppState> {

  unsubscribers: (() => void)[] = []

  constructor(props: IAppProps) {
    super(props)

    this.state = {
      loading: false,
      urn: '',
      notifications: notifications.getState()
    }
  }

  render() {
    if (this.state.isLogged === undefined) return null;
    // console.log('rendered', this.state)

    const routes = (!this.state.isLogged) ?
      <Switch><Route path="/" component={pages.login} /></Switch> :
      <Switch>
        <Route path="/home" component={pages.home} exact />
        <Route path="/project/:id" component={pages.project} exact />
        {User.isAdmin ? <Route path="/admin" component={pages.admin} exact /> : null}
        <Route path="*">
          <Redirect to="/home" />
        </Route>
      </Switch>

    return (
      <div className="App font-sans h-screen w-screen overflow-hidden">
        <Router>
          {routes}
          <div className="fixed top-0 right-0 p-2 max-w-lg w-80 sm:max-w-full z-100 text-right">
            {this.state.loading ? <CircularProgress /> : null}
            {this.state.notifications.map((alert, index) =>
              <Alert
                className="mb-2 w-100 overflow-auto"
                key={index}
                severity={alert.severity}
                onClose={
                  () => {
                    notifications.remove(index)
                  }}>
                {alert.message}
              </Alert>
            )}
          </div>
        </Router>
      </div>
    )
  }

  componentDidMount() {

    this.unsubscribers.push(
      notifications.subscribe((notifications: INotification[]) => {
        this.setState({
          notifications
        })
      }))

    this.unsubscribers.push(
      notifications.loading.subscribe((loading: boolean) => {
        this.setState({
          loading
        })
      }))


    USER_STATE.subscribe((user) => {
      this.setState({
        isLogged: Boolean(User)
      })
    })

    handshake().then(() => {
      this.setState({
        isLogged: Boolean(User)
      })

      document.querySelector(".loader-container")?.remove();
    })

  }

  componentWillUnmount() {
    this.unsubscribers.map(unsubscribe => unsubscribe())
    this.unsubscribers = []
  }
}

export default App
