import { ChangeEvent, Component } from 'react'
import Dropzone from 'react-dropzone'

import API from '@services/ApiService'
import { IProject } from '@utils/types'

import Loader from '@components/common/Loader'

import { FaCloudUploadAlt as UploadIcon } from 'react-icons/fa'

enum TYPES {
    'D',
    'M',
    'C'
}

const types = {
    [TYPES.C]: 'Create',
    [TYPES.M]: 'Modify',
    [TYPES.D]: 'Delete'
}

export default class ProjectForm extends Component<{ data?: IProject, callback?: (project: IProject) => void, delete?: boolean }, {}> {
    state = {
        files: 0,
        name: this.props.data?.name,
        description: this.props.data?.description,
        isLoading: false
    }

    type = this.props.data ? this.props.delete ? TYPES.D : TYPES.M : TYPES.C

    async onSubmit(e: any) {
        e.preventDefault()
        this.setState({isLoading: true})
        const data = new FormData(e.target)
        let result
        try {
            if (this.type === TYPES.C) {
                result = await API.post('/projects', data)
            } else if (this.type === TYPES.M) {
                result = await API.put('/projects/' + this.props.data?.id, data)
            } else if (this.type === TYPES.D) {
                result = await API.delete('/projects/' + this.props.data?.id)
            }
            if (this.props.callback) this.props.callback(result as any)
        } catch (error) {

        }

        this.setState({isLoading: false})
    }

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading}>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <label className="block">
                        <span className="text-white">Name</span>
                        <input className="form-input p-1 block w-full h-8 outline-none font-bold" 
                            placeholder="Name" 
                            name="name" 
                            value={this.state.name} 
                            onChange={this.handleChange.bind(this)} 
                            readOnly={this.props.delete}
                            required 
                        />
                    </label>
                    <label className="block">
                        <span className="text-white">Description</span>
                        <input className="form-input p-1 block w-full h-8 outline-none font-bold" 
                            placeholder="Description" 
                            name="description" 
                            value={this.state.description} 
                            onChange={this.handleChange.bind(this)} 
                            readOnly={this.props.delete}
                        />
                    </label>

                    <div className="block">
                        <span className="text-white">Background image</span>
                        <Dropzone
                            accept="image/*">
                            {({ getRootProps, getInputProps }) => (
                                <div className="h-full w-full flex flex-col items-center px-4 py-6 
                                        bg-white hover:bg-gray-600 hover:text-white text-gray-600 ease-linear transition-all duration-150
                                        shadow-md tracking-wide uppercase border border-blue cursor-pointer align-middle"
                                    {...getRootProps()}>
                                    <UploadIcon className="w-7 h-7 inline-block mr-2" />
                                    {
                                        this.state.files ?
                                            <>
                                                <span className="mt-2 text-base leading-normal">{this.state.files} Files selected</span>
                                            </>
                                            :
                                            <>
                                                <span className="mt-2 text-base leading-normal">Select a file</span>
                                                <span className="mt-2 text-base leading-normal">Or drag and drop</span>
                                            </>
                                    }
                                    <input {...getInputProps()} name="image" onChange={(e) => {
                                        this.setState({ files: e.target.files?.length })
                                    }} />
                                </div>
                            )}
                        </Dropzone>
                    </div>

                    <button className="text-alis-1 
                            bg-transparent border border-solid border-alis-1 hover:bg-alis-1 hover:text-white 
                            active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none"
                            type="submit" >
                        {types[this.type] + ' project'}
                    </button>
                </form>
            </Loader>
        )
    }
}
