import { useState } from 'react'
import { Switch } from '@headlessui/react'

interface ISwitchProps { 
  name: string,
  label: string, 
  enabled: boolean,
  onChange?: (enabled: boolean) => void
}
export default function AppSwitch(props: ISwitchProps) {
  const [enabled, setEnabled] = useState(props.enabled)

  function onchange(enabled: boolean){
    setEnabled(enabled)
    if(props.onChange) props.onChange(enabled)
  }

  return (
    <div className="flex items-center h-full text-center m-auto">
      <Switch.Group>
        <Switch
          checked={enabled}
          onChange={onchange}
          className={`${enabled ? 'bg-alis-2' : 'bg-gray-600'}
            relative inline-flex flex-shrink-0 h-4 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span className="sr-only">{props.label}</span>
          <span
            aria-hidden="true"
            className={`${enabled ? 'translate-x-full' : 'translate-x-0'}
              pointer-events-none inline-block h-full w-1/2 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
          <input type="checkbox" name={props.name} checked={enabled} hidden readOnly/>
        </Switch>
        <Switch.Label className="ml-2 select-none">{props.label}</Switch.Label>
      </Switch.Group>
    </div>
  )
}

AppSwitch.defaultProps = {
  enabled: false
}