import API from '@services/ApiService'
import { IUser } from '@app/utils/types'

import globalState from '@utils/classes/globalState'

import storage from '@services/StorageService'


export const USER_STATE = new globalState<IUser | null>(null)

export let User: IUser

// Alter defaults after instance has been created
USER_STATE.subscribe((user: any) => {
    User = user
});

//handshake
export const handshake = async (token?: string) => {
    try {
        const access_token = token || storage.getItem('accessToken')
        
        if(access_token){
            API.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            const response = await API.get('/auth')
            if (response?.status === 200) {
                USER_STATE.setState(response.data)
            }
        }
    } catch (error) {
        console.error(error)
    }
}

export const login = async (username: string, password: string, remember: Boolean) => {
    try {
        const response = await API.post('/auth', null, {
            auth: {
                username: username,
                password: password
            },
        })

        if (response?.status === 200) {
            const accessToken = response.data

            storage.removeItem('accessToken');

            storage[(remember ? 'local' : 'session')].setItem('accessToken', accessToken)
            
            // API.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
            handshake(accessToken)
        }

        return response

    } catch (error) {
        console.error(error)
        throw error
    }
}

export const logout = async () => {
    try {
        const response = await API.delete('/auth')

        if (response.status === 200) {
            storage.removeItem('accessToken');
            USER_STATE.setState(null)
        }

        return response
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const forgotPassword = async (email: string) => {
    try {
        const response = await API.post('/auth/forgot', {
            email: email
        })
        return response
    } catch (error) {
        console.error(error)
        throw error
    }
}