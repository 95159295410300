import { Component } from 'react'

import Excel from '@utils/functions/excel'

import UploadComponent from './UploadComponent'

import notifications, { add } from "@services/NotificationService";

import API from '@services/ApiService';

interface IProps {
    project_id: string
    handleClick: () => void
}

export default class Docs extends Component<IProps, {}> {

    state = {
        uploaded: false
    }

    render() {
        return (
            <div className='flex flex-col justify-center flex-grow'>
                <UploadComponent handleClick={this.props.handleClick}
                    downloadTemplate={this.downloadDocument.bind(this)} 
                    upload={this.upload.bind(this)} 
                    uploaded={this.state.uploaded} 
                    title="DOCUMENTS" />
            </div>
        )
    }

    async downloadDocument() {
        try {

            const response = await API.get(`/projects/${this.props.project_id}/documents`)
            if (response.status === 200) {
                let documents = response.data
                if (!documents.length) documents = [{
                    originalname: "",
                }]

                for (const i in documents) {
                    delete documents[i].id
                    delete documents[i].mimetype
                    delete documents[i].size
                    delete documents[i].createdAt
                    delete documents[i].updatedAt
                    delete documents[i].path
                    delete documents[i].encoding
                    documents[i] = { ...documents[i] }
                }

                Excel.download({ DOCUMENTS: documents }, 'Document template.xlsx')
            }
        }
        catch (error) {
            add({
                message: 'Failed to get project docs!',
                severity: "error"
            },2000)
        }
    }

    async upload(files: File[]) {
        try {

            if (files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

                const p = []
                for (const i in files) {
                    p.push(Excel.parse(files[i]))
                }
                const parsedFiles = await Promise.all<any>(p)
                const body = []
                for (const i in parsedFiles) {
                    if (parsedFiles[i].DOCUMENTS) {
                        body.push(...parsedFiles[i].DOCUMENTS)
                    }
                }

                for (const i in body) {

                    const originalname = body[i].originalname
                    const description = body[i].description
                    const type = body[i].type
                    const revision = body[i].revision

                    if (originalname == null) {

                    }
                    else {
                        const response = await API.patch(`/projects/${this.props.project_id}/documents`, [{
                            originalname: originalname,
                            description: description,
                            type: type,
                            revision: revision
                        }])
                        if (response.status === 200 || response.status === 201 || response.status === 204) {
                            const data = response.data

                            this.setState({ uploaded: true })

                        } else {
                            notifications.add({
                                message: 'Documents update error',
                                severity: 'error'
                            },2000)
                        }
                    }
                }
            }
            else {

                const form = new FormData()

                for (const i in files) {
                    form.append('documents', files[i])
                }

                const response = await API.post(`/projects/${this.props.project_id}/documents`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (response.status === 200) {
                    this.setState({ uploaded: true })
                }
            }

        } catch (error) {
            add({
                message: 'Failed to upload project documents!',
                severity: "error"
            },2000)
        }
    }
}
