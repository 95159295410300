import { Component, MouseEvent, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom';

import AppDropdown from '@app/components/common/AppDropdown';

import appLogo from '@assets/logo.svg';

import { User, logout } from '@app/services/UserService'

import UserIcon from '@assets/UserIcon.svg';

interface ITopBarProps extends HTMLAttributes<HTMLDivElement> {
  auth: boolean,
  title?: string
}

interface ITopBarStates {
  open: boolean
  anchorEl: HTMLElement | null
}
export default class TopBar extends Component<ITopBarProps, ITopBarStates>{
  constructor(props: ITopBarProps) {
    super(props)

    this.state = {
      open: false,
      anchorEl: null
    }
  }

  handleMenu(event: MouseEvent<HTMLElement>) {
    this.setState({
      anchorEl: event.currentTarget,
      open: true
    });
  };

  handleClose() {
    this.setState({
      anchorEl: null,
      open: false
    });
  };

  render() {
    return (
      <div>
        <nav className="bg-alis-dark shadow">
          <div className="mx-auto">
            <div className="h-12 flex items-center justify-between">
              <div className="flex items-center mx-4">
                <Link to={`/`}>
                  <img className="h-8 w-8" src={appLogo} alt="Workflow" />
                </Link>
              </div>
              <div className="flex-1 h-full text-center text-3xl text-white">
                {this.props.title}
              </div>
              <div className="flex p-2">

                <div className="ml-4 flex items-center md:ml-6">
                  <AppDropdown
                    items={[{
                      text: 'Disconnect',
                      onClick: e => logout()
                    }]} >
                    <div className="flex items-end">
                    <img className="w-7 h-7 inline-block mr-2" src={UserIcon} alt="User icon" />{User.username}
                    </div>
                  </AppDropdown>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}