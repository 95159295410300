import Projects from './Projects'
import Users from './Users'

import {
    Link,
    useLocation,
    useHistory
} from "react-router-dom";

import { useEffect, useState } from 'react';

import appLogoNew from '@app/assets/logo_new.png';

import Loader from '@components/common/Loader'

import {AiOutlineFundProjectionScreen, AiOutlineUser} from 'react-icons/ai'
import AppDropdown from '@app/components/common/AppDropdown';

import UserIcon from '@assets/UserIcon.svg';
import { User, logout } from '@app/services/UserService';
import { FiLogOut } from 'react-icons/fi';

export default function Home() {

    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const [isLoading, setLoading] = useState<boolean>(false)

    const [projectColor, setProjectColor] = useState<string>()
    const [userColor, setUserColor] = useState<string>()

    let history = useHistory();

    const items = []

    if (User.isAdmin) {
        items.push({
            text: 'Admin',
            onClick: () => history.push('/admin')
        })
    }

    items.push({
        text: 'Disconnect',
        onClick: () => logout()
    })
    
    const li_class = "h-12 py-1 px-4 text-center"

    let content = null

    const view = query.get('view')

    content = <Projects />
    if (view == 'projects') content = <Projects />
    if (view == 'users') content = <Users />

    useEffect(() => {
        if (view == 'projects') {
            setProjectColor("#2BD2FF")
        }
        else {
            setProjectColor("#CBD5E1")
        }
        if (view == 'users') {
            setUserColor("#2BD2FF")
        }
        else {
            setUserColor("#CBD5E1")
        }
    })
   
    return (
        <div className="h-full w-full flex flex-col overflow-hidden">
            <div className="flex flex-shrink flex-grow">
                <div className="flex flex-col bg-white text-black p-2">
                    <div className="">
                        <div className="flex p-2">
                        <img className="h-13 w-12 m-auto" src={appLogoNew} alt="Workflow" />
                        </div>
                        <AppDropdown
                           items={[{
                            text : "",
                            icon : <FiLogOut onClick={ e => logout()}/>
                        }]} >
                            <div className="flex flex-col w-100">
                                <img className="h-9 w-9 m-auto" src={UserIcon} alt="User icon" />
                            </div>
                        </AppDropdown>
                    </div>
                    <div className="flex-grow flex">
                        <ul className="self-center overflow-auto">
                            <Link to={location.pathname + '?view=projects'}><li className={li_class}> <AiOutlineFundProjectionScreen color={projectColor} size={30} className=""></AiOutlineFundProjectionScreen></li></Link>
                            {User.isAdmin? <Link to={location.pathname + '?view=users'}><li className={li_class}> <AiOutlineUser color={userColor} size={30} className=""></AiOutlineUser></li></Link> : ""}
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col flex-grow overflow-auto">
                    <div className="flex-shrink flex-grow" >
                        <Loader isLoading={isLoading}>
                            {content}
                        </Loader>
                    </div>
                </div>
            </div>
        </div>
    )
}


