import { Component } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { CgComment } from 'react-icons/cg'

interface IProps extends ICellRendererParams {
  text?: string
  onClick: (data: any) => void
  comment: string
}

interface IState{}

export default class Comment extends Component<IProps, IState> {
  render() {
    let textColor = (this.props.data.comment != null) ? "text-alis-2" : "text-slate-200"
    if(this.props.data.comment == "") textColor = "text-slate-200"
    
    return (
      <div>
        <CgComment className={`mt-3 ${textColor}`} size={23} onClick={() => this.props.onClick(this.props.data)} />
      </div>
    )
  }
}