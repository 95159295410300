import { Component } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AgGridEvent, CellEvent, ColumnApi, GridApi } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import ButtonRenderer from './renderers/Button'
import DetailsRenderer from './renderers/Details'
import DocAddDeleteRenderer from './renderers/DocAddDelete'

import Excel from '@utils/functions/excel'

import notifications, { add } from "@services/NotificationService";

import API from '@services/ApiService';
import { IDocument, ISystem } from '@utils/types'

import ContextMenu from '@components/contextmenu'
import { contextMenu } from 'react-contexify';
import ModalPdf from '../modal/ModalPdf';
import { User } from '@app/services/UserService';

const FileSaver = require('file-saver');

interface IGridProps {
    project_id: string
    system?: ISystem
    addToProject?: boolean
    hideColumn: boolean

    destination: "deleteFromProject" | "addToSystem" | "deleteFromSystem" | "exportEvolis"
}

interface IGridState {
    data?: IDocument[]
    show?: boolean
    docModal?: IDocument
    numberOfRow: number
    numberOfSelectedRow: number
}

export default class Grid extends Component<IGridProps, IGridState> {

    gridApi?: GridApi
    columnApi?: ColumnApi
    autoSize = false

    constructor(props: IGridProps) {
        super(props)

        this.state = {
            show: false,
            docModal: undefined,
            numberOfRow: 0,
            numberOfSelectedRow: 0,
        }
    }

    contextMenu(data: CellEvent) {

        if (!data.node.isSelected()) data.node.setSelected(true, true)

        if (User.isAdmin == true) {
            contextMenu.show({
                id: 'DOCS_CTX',
                event: data.event as any
            });
        }
    }

    async deleteDocuments() {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const ids = selectedNodes.map(node => node.data.id);

        const answer = window.confirm("Are you sure you wish to delete these documents from the system ?");
        if (!answer) return

        ids.forEach(async id => {
            const response = await API.delete(`/projects/${this.props.project_id}/documents/${id}`)

            if (response.status === 200 || response.status === 202 || response.status === 204) {
                this.getDocuments()
            }
        });
    }

    async getDocuments() {
        try {
            this.autoSize = true
            const url = (this.props.destination == "deleteFromProject" || this.props.destination == "addToSystem" || this.props.destination == "exportEvolis") ?
                `/projects/${this.props.project_id}/documents` : `/projects/${this.props.project_id}/systems/${this.props.system?.tag}/documents/`

            const response = await API.get(url)
            if (response.status === 200) {
                const data = response.data
                this.setState({ data })
            }
        } catch (error) {
            this.autoSize = false
            add({
                message: 'Failed to get documents!',
                severity: "error"
            }, 2000)
        }
    }

    async associateToSystem() {

        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const names = selectedNodes.map(node => node.data.originalname);

        names.map(async originalname => {

            const response = await API.patch(`/projects/${this.props.project_id}/systems/${this.props.system?.tag}/documents/add`, [originalname])

            if (response.status === 200 || response.status === 201 || response.status === 204) {

                notifications.add({
                    message: 'Document associated to system',
                    severity: 'success'
                }, 2000)
            }
        })
    }

    async generateM_DOCS() {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const names = selectedNodes.map(node => node.data.originalname);

        const response = await API.get(`/projects/${this.props.project_id}/documentsDownload`, { responseType: 'arraybuffer' })
        if (response.status == 200) {
            var blob = new Blob([response.data], { type: "application/zip" })
            FileSaver.saveAs(blob, "DOCUMENTS.zip");
        }

        const responseExcel = await API.post(`/projects/${this.props.project_id}/M_DOCS`, names)
        if (responseExcel.status == 200) Excel.download({ "M_DOCS": responseExcel.data }, 'M_DOCS.xlsx')
    }

    getMenu() {
        const menu: any[] = []

        if (this.props.destination == "exportEvolis") {
            menu.push({
                type: 'item',
                text: 'Generate M_DOCS',
                onClick: this.generateM_DOCS.bind(this)
            })
        }
        else {
            menu.push({
                type: 'item',
                text: 'Delete documents',
                onClick: this.deleteDocuments.bind(this)
            })
        }
        return menu
    }

    render() {
        const defaultColDef = {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] },
            cellStyle: { fontSize: '11px' }
        };

        const menu = this.getMenu()

        return (
            !this.state.data ?
                <h1 className='text-gray-500 text-sm'>0 document available for this system</h1> :
                <div className="ag-theme-material h-full w-full">

                    {(this.state.show && this.state.docModal) &&
                        <ModalPdf hideModal={() => {
                            this.setState({ show: false });
                        }}
                            doc={this.state.docModal!}
                            project_id={this.props.project_id} />}

                    <ContextMenu menu={menu} MENU_ID='DOCS_CTX'></ContextMenu>
                    <AgGridReact
                        rowData={this.state.data}
                        defaultColDef={defaultColDef}
                        suppressDragLeaveHidesColumns={true}
                        rowSelection={'multiple'}
                        onFirstDataRendered={this.onGridReady.bind(this)}
                        onCellContextMenu={this.contextMenu}
                        onSelectionChanged={this.onSelectionChanged.bind(this)}
                        preventDefaultOnContextMenu={true}
                        frameworkComponents={{
                            ButtonRenderer,
                            DetailsRenderer,
                            DocAddDeleteRenderer
                        }}
                        getRowNodeId={(data) => {
                            return data.id;
                        }}
                        onGridReady={this.onGridReady.bind(this)}
                        onModelUpdated={() => this.autoSizeAll()}
                    >
                        <AgGridColumn resizable={false} maxWidth={50} suppressFiltersToolPanel={true} sortable={false}
                            cellRenderer="DetailsRenderer" cellRendererParams={{
                                type: "PDF",
                                clicked: (data: IDocument) => {
                                    this.setState({
                                        show: true,
                                        docModal: data
                                    });
                                }
                            }} />
                        <AgGridColumn field="originalname" headerName="NAME" />
                        <AgGridColumn field="description" headerName="DESCRIPTION"
                            editable={User.isAdmin}
                            onCellValueChanged={(params: any) => {
                                var document: IDocument = params.data
                                this.updateDocument(document, document)
                            }} />
                        <AgGridColumn field="type" headerName="TYPE"
                            editable={User.isAdmin}
                            onCellValueChanged={(params: any) => {
                                var document: IDocument = params.data
                                this.updateDocument(document, document)
                            }} />
                        <AgGridColumn field="revision" headerName="REVISION"
                            editable={User.isAdmin}
                            onCellValueChanged={(params: any) => {
                                var document: IDocument = params.data
                                this.updateDocument(document, document)
                            }} />
                        <AgGridColumn field="size" headerName='SIZE' hide={this.props.hideColumn} />
                        <AgGridColumn field="createdAt" headerName="UPLOAD DATE" hide={this.props.hideColumn}
                            filter="agDateColumnFilter"
                            cellRenderer={(data: any) => {
                                return data.value ? (new Date(data.value)).toLocaleDateString() : '';
                            }} />
                        <AgGridColumn field=""
                            cellRenderer="ButtonRenderer"
                            resizable={false} maxWidth={50}
                            cellRendererParams={{
                                text: 'Download',
                                clicked: (row: IDocument, value: string) => {
                                    this.downloadFile(row)
                                }
                            }}
                            filter={false}
                            sortable={false} />

                        <AgGridColumn field=""
                            cellRenderer="DocAddDeleteRenderer"
                            resizable={false} maxWidth={50} suppressFiltersToolPanel={true} sortable={false}
                            cellRendererParams={{
                                text: this.props.destination,
                                system: this.props.system,
                                clicked: (row: IDocument, value: string) => {
                                    this.deleteAddDocFromProject(row)
                                }
                            }} />
                    </AgGridReact>
                    <div className='bg-white h-10'>
                        <div className='flex'>
                            <h1 className='m-2 text-sm font-semibold text-gray-300'>Total : {this.state.numberOfRow}</h1>
                            <h1 className='m-2 text-sm font-bold text-gray-400'>Rows selected : {this.state.numberOfSelectedRow}</h1>
                        </div>
                    </div>
                </div>
        );
    }

    autoSizeAll(skipHeader = false): void {
        if (!this.columnApi) return

        const columns = this.columnApi.getAllColumns()
        const allColumn = (columns || []).filter((column) => {
            return column.isResizable()
        })
        this.columnApi.autoSizeColumns(allColumn, skipHeader)
    }

    onGridReady(params: AgGridEvent): void {
        // or setState if using components
        this.gridApi = params.api
        this.columnApi = params.columnApi
        // this.autoSizeAll()

        const numberOfRow = this.gridApi.getDisplayedRowCount()
        this.setState({ numberOfRow })

        this.gridApi.sizeColumnsToFit();
    }

    onSelectionChanged = (event: any) => {
        var rowCount = event.api.getSelectedNodes().length;
        this.setState({ numberOfSelectedRow: rowCount })
    };

    async downloadFile(data: IDocument) {
        try {
            const response = await API.get(`/projects/${this.props.project_id}/documents/${data.id}/download`, { responseType: 'arraybuffer' })
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.originalname); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove()
            }
        } catch (error) {
            add({
                message: `download failed!`,
                severity: "error"
            }, 2000)
        }
    }

    async deleteAddDocFromProject(data: IDocument) {

        if (this.props.destination == "deleteFromProject") {

            var answer = window.confirm("Are you sure you wish to delete this document ?")
            if (answer) {

                const response = await API.delete(`/projects/${this.props.project_id}/documents/${data.id}`)
                if (response.status === 200 || response.status === 202 || response.status === 204) {

                    notifications.add({
                        message: `Document suppressed!`,
                        severity: "success"
                    }, 2000)

                    this.getDocuments()
                }
            }
        }
        else if (this.props.destination == "deleteFromSystem") {

            var answer = window.confirm("Are you sure you wish to delete this document from the system ?")
            if (answer) {

                const response = await API.patch(`/projects/${this.props.project_id}/systems/${this.props.system?.tag}/documents/${data.id}/remove`)
                if (response.status === 200 || response.status === 202 || response.status === 204) {

                    notifications.add({
                        message: `Document suppressed!`,
                        severity: "success"
                    }, 2000)

                    this.getDocuments()
                }
            }
        }
        else if (this.props.destination == "addToSystem") {

            const response = await API.patch(`/projects/${this.props.project_id}/systems/${this.props.system?.tag}/documents/add`, [data.originalname])

            if (response.status === 200 || response.status === 201 || response.status === 204) {

                notifications.add({
                    message: 'System import success!',
                    severity: 'success'
                }, 2000)

                this.getDocuments()
            }
        }
    }

    async updateDocument(document: IDocument, patch_data: Partial<IDocument>) {
        const response = await API.patch(`/projects/${this.props.project_id}/documents/${document.id}`, patch_data)
        if (response.status === 200 || response.status === 201) {
            const rowNode = this.gridApi?.getRowNode(document.id.toString());
            rowNode?.setData({ ...rowNode?.data, ...patch_data });
        }
    }

    componentDidMount() {
        this.getDocuments()
    }

}