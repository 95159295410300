import { Component } from 'react'

import Excel from '@utils/functions/excel'
import notifications, { add } from '@app/services/NotificationService'
import API from '@services/ApiService'

import UploadComponent from './UploadComponent'

interface IProps {
    handleClick: () => void
    project_id: string
}

export default class Association extends Component<IProps, {}> {

    state = {
        uploaded: false
    }

    render() {
        return (
            <div className='flex flex-col justify-center flex-grow'>
                <UploadComponent handleClick={this.props.handleClick} 
                    downloadTemplate={this.downloadAssociation.bind(this)} 
                    upload={this.uploadExcel.bind(this)} 
                    uploaded={this.state.uploaded} 
                    title="ASSOCIATION"/>
            </div>
        )
    }

    async uploadExcel(files: File[]) {
        const p = []
        for (const i in files) {
            p.push(Excel.parse(files[i]))
        }
        const parsedFiles = await Promise.all<any>(p)
        const body = []
        for (const i in parsedFiles) {
            if (parsedFiles[i].DOC) {
                body.push(...parsedFiles[i].DOC)
            }
        }

        for (const i in body) {

            const systemTag = body[i].SystemTag
            const docName = body[i].DocumentName

            if (systemTag == null || docName == null) {
            
            }
            else {
                const response = await API.patch(`/projects/${this.props.project_id}/systems/${systemTag}/documents/add`, [docName])

                if (response.status === 200 || response.status === 201 || response.status === 204) {
                    const data = response.data

                    this.setState({ uploaded: true })
                }
                else {
                    notifications.add({
                        message: 'Systems import error',
                        severity: 'error'
                    })
                }
            }
        }
    }

    async downloadAssociation() {
        try {
            const data = [{
                SystemTag: "",
                DocumentName: "",
            }]

            const response = await API.get(`/projects/${this.props.project_id}/documents`)
            if (response.status === 200) {
                let documents = response.data
                if (!documents.length) documents = [{
                    originalname: "",
                }]

                for (const i in documents) {
                    delete documents[i].id
                    delete documents[i].mimetype
                    delete documents[i].size
                    delete documents[i].createdAt
                    delete documents[i].updatedAt
                    delete documents[i].path
                    delete documents[i].encoding
                    documents[i] = { ...documents[i] }
                }

                const responseSystems = await API.get(`/projects/${this.props.project_id}/systems`)
                if (responseSystems.status === 200) {
                    let systems = responseSystems.data
                    if (!systems.length) systems = [{
                        tag: "",
                    }]

                    for (const i in systems) {
                        delete systems[i].scheduling_tag
                        delete systems[i].cof
                        delete systems[i].inspection_strategie
                        delete systems[i].process_function
                        delete systems[i].corrosion_loop
                        delete systems[i].inspection_type
                        delete systems[i].navisworks_status
                        delete systems[i].overview_status
                        delete systems[i].isometrics_status
                        delete systems[i].due_date
                        delete systems[i].user
                        delete systems[i].comment
                        systems[i] = { ...systems[i] }
                    }
                    Excel.download({ DOC: data, DOCUMENTS: documents, SYSTEMS: systems } , 'Association template.xlsx')
                }
            }
        }
        catch (error) {
            add({
                message: 'Failed to get project docs!',
                severity: "error"
            },2000)
        }
    }
}
