import { Component } from 'react'

import { IClass, ITemplate } from '@utils/types'

import Upload from '@components/Upload'

interface IProps {
    class: IClass
    template?: ITemplate
    submitHandler: (bloc: string, file: File, classToUpdate : IClass) => void
}

interface IState {
    bloc: string
    file?: File
}

export default class ClassTemplate extends Component<IProps, IState> {
    render() {
        return (
            <div className="grid grid-cols-3 p-2">
                <label className="">
                    <h1 className="mt-1" >{this.props.class.name}</h1>
                </label>
                <label className="">
                    <input className="p-1 block border-2 border-double border-zinc-300"
                        placeholder="Name"
                        name="bloc"
                        defaultValue={this.props.template?.bloc}
                        value={this.state?.bloc}
                        onInput={(e) => this.setState({ bloc: e.currentTarget.value })}
                        required />
                </label>
                <label className="">
                    <Upload associated={this.props.template != undefined} minimized={true} name="file" multiple={false} uploadHandler={this.fileHandler.bind(this)} />
                </label>
            </div>
        )
    }

    fileHandler(files: File[]){
        if(!(files[0] instanceof File)) return
            this.props.submitHandler(this.state.bloc, files[0], this.props.class) 
    }

    componentDidMount(){
        if(this.props.template?.bloc != null)
        this.setState({bloc : this.props.template?.bloc})    
    }
}
