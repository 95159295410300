import { Component } from 'react'

import DocumentsGrid from '../grids/DocumentsGrid'
import Modal from './Modal'

import { IDocument, ISystem } from '@utils/types'

interface IProps {
    hideModal?: () => void
    system: ISystem
    docs?: IDocument[]
    project_id: string
}

interface IState {

}

export default class ModalDocs extends Component<IProps, IState> {
    render() {
        return (
            <Modal handleClose={this.props.hideModal}>
                <div className='relative bg-white self-center justify-self-center max-h-full max-w-full overflow-hidden flex flex-col'>
                    <div className='bg-[#ededed] pb-4 pl-4 pt-4'>
                        <h1 className='text-alis-2 text-base font-bold'>System ID: </h1>
                        <h1 className='font-bold text-black'>{this.props.system?.tag}</h1>
                    </div>

                    <div className='h-96 w-[900px] max-h-full max-w-full'>
                        <DocumentsGrid destination={"addToSystem"} hideColumn={true} project_id={this.props.project_id} addToProject={true} system={this.props.system} />
                    </div>

                </div>
            </Modal>
        )
    }
}
