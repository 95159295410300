 const storage = {
    session: sessionStorage,
    local: localStorage,
    sessionToLocal: async (name: string) => {
        const value = storage.local.getItem(name)
        if(value === null) throw 'Item ' + name + ' not found in session'
        storage.local.setItem(name, value)
        storage.session.removeItem(name)
        return value
    },
    localToSession: async (name: string) => {
        const value = storage.local.getItem(name)
        if(value === null) throw 'Item ' + name + ' not found locally'
        storage.session.setItem(name, value)
        storage.local.removeItem(name)
        return value
    },
    getItem(name: string){
        const sessionValue = storage.session.getItem(name)
        if(sessionValue) return sessionValue
        const localValue = storage.local.getItem(name)
        if(localValue) return localValue

        return null
    },
    removeItem(name: string){
        storage.session.removeItem(name)
        storage.local.removeItem(name)
    },
    clear(){
        storage.session.clear()
        storage.local.clear()
    }
}

export default storage