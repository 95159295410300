import { Component } from 'react'

import Excel from '@utils/functions/excel'

import notifications, { add } from "@services/NotificationService";

import API from '@services/ApiService';

import UploadComponent from './UploadComponent';

interface IProps {
    project_id: string
    handleClick: () => void
}

export default class Systems extends Component<IProps, {}> {

    state = {
        uploaded: false
    }

    render() {
        return (
            <div className='flex flex-col justify-center flex-grow'>
                <UploadComponent handleClick={this.props.handleClick}
                    downloadTemplate={this.downloadSystems.bind(this)}
                    upload={this.importSystems.bind(this)}
                    uploaded={this.state.uploaded}
                    title="SYSTEMS" />
            </div>
        )
    }

    async downloadSystems() {
        try {
            const response = await API.get(`/admin/projects/${this.props.project_id}/export/systems`)
            if (response.status === 200) {
                let data = response.data
                Excel.download(data, 'Systems export.xlsx')
            }
        } catch (error) {
            add({
                message: 'Failed to get system export!',
                severity: "error"
            },2000)
        }
    }

    async importSystems(files: File[]) {
        try {
            const p = []
            if (!(files && files[0])) return

            const body = await Excel.parse(files[0])

            const response = await API.post(`/admin/projects/${this.props.project_id}/import/systems`, body)

            if (response.status === 200) {

                this.setState({ uploaded: true })

                notifications.add({
                    message: 'Systems import success!',
                    severity: 'success'
                },2000)
            }
        } catch (error: any) {

            const data = error.data.data

            let Class: { ClassName: any; Error: any; }[] = []
            let System: { SystemTag: any; Error: any; }[] = []
            let TechnicalClass: { TechnicalClassName: any; Error: any; }[] = []

            data.Class.map((projectClass: any) => {
                if (projectClass.error == undefined) {}
                else if(projectClass.error[0] == "current transaction is aborted, commands ignored until end of transaction block"){}
                else {
                    var info = {
                        "ClassName" : projectClass.name,
                        "Error" : projectClass.error[0]
                    }
                    Class.push(info)
                }
            })
            data.System.map((system: any) => {
                if (system.error == undefined) { }
                else if(system.error[0] == "current transaction is aborted, commands ignored until end of transaction block"){}
                else {
                    var info = {
                        "SystemTag" : system.tag,
                        "Error" : system.error[0]
                    }
                    System.push(info)
                }
            })
            data.TechnicalClass.map((technicalClass: any) => {
                if (technicalClass.error == undefined) { }
                else if(technicalClass.error[0] == "current transaction is aborted, commands ignored until end of transaction block"){}
                else {
                    var info = {
                        "TechnicalClassName" : technicalClass.name,
                        "Error" : technicalClass.error[0]
                    }
                    TechnicalClass.push(info)
                }
            })

            Excel.download({ClassErrors : Class, SystemErrors : System, TechnicalClassErrors : TechnicalClass} , 'Errors.xlsx')

            add({
                message: 'Failed to import project systems!',
                severity: "error"
            },2000)
        }
    }
}
