import {
  Menu,
  Item,
  Separator,
  Submenu
} from "react-contexify";

import React, { Component, ReactPortal } from 'react'
import { createPortal } from 'react-dom';

interface IProps {
  MENU_ID: string
  menu: IMenuItem[]
}

interface IState {
  container: HTMLElement | null
}

interface IMenuItem {
  type: 'item' | 'hr' | 'sub',
  text?: string
  disabled?: boolean
  children?: IMenuItem[]
  onClick?: () => void
}

export default class System extends Component<IProps, IState> {
  static system_id: string | undefined;

  container = React.createRef<HTMLDivElement>()

  parseMenuItem(data: IMenuItem) {
    switch (data.type) {
      case 'item':
        return <Item className="" onClick={data.onClick} disabled={data.disabled}>{data.text}</Item>
      case 'hr':
        return <Separator></Separator>
      case 'sub':
        return <Submenu label={data.text}>
          {data.children?.map((childData: IMenuItem) => this.parseMenuItem(childData))}
        </Submenu>
      default:
        break;
    }
  }
  
  render() {
    return (
      <div ref={this.container}>
        {this.container.current &&
          <Portal container={this.container.current}>
            <Menu className="bg-slate-400 py-1 rounded-none border-4 border-double" id={this.props.MENU_ID}>
              {
                this.props.menu.map((data: any) => {
                  return this.parseMenuItem(data)
                })
              }
            </Menu>
          </Portal>
        }
      </div>
    )
  }
}

function Portal(props: React.PropsWithChildren<{container: HTMLElement}>) {
  return createPortal(props.children, props.container);
}