import React, { Component } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { VscFilePdf } from 'react-icons/vsc'

interface IProps {
    clicked: (data: any) => void
    text?: string
    data?: any
    type?: string
}

interface IState {

}

export default class DocPreview extends Component<IProps, IState> {

    render() {

        return (
            <div>
                {this.props.type == "PDF" ?
                    <VscFilePdf className="text-slate-500 mt-2" size={25} onClick={() => this.props.clicked(this.props.data)} />
                    : <BiInfoCircle className="text-slate-500 mt-2" size={25} onClick={() => this.props.clicked(this.props.data)} />
                }
            </div>
        )
    }
}
