import { useState, useEffect } from "react";
import {
    Link,
    useParams,
    useLocation
} from "react-router-dom";

import { add } from "@services/NotificationService";

import { User, logout } from '@app/services/UserService'

import API from '@services/ApiService'

import Systems from "./Systems";
import Items from "./Items";
import Documents from "./Documents";
import Evolis from "./Evolis/Evolis";
import Basket from "./Basket";

import Admin from "./admin";

import Loader from '@components/common/Loader'
import AppDropdown from '@components/common/AppDropdown';

import UserIcon from '@assets/UserIcon.svg';
import appLogoNew from '@app/assets/logo_new.png';

import { FaCubes } from 'react-icons/fa'
import { BiCube } from 'react-icons/bi'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { BsBasket3 } from 'react-icons/bs'
import { RiTableLine } from 'react-icons/ri'
import { FiLogOut } from 'react-icons/fi'

import { ReactComponent as LogoEvolis } from '@assets/logo_evolis.svg';

interface ProjectParams {
    id: string
};

function Document() {
    const params = useParams<ProjectParams>()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const [project, setProject] = useState<any | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [basket, setBasket] = useState<number>();

    const [systemColor, setSystemColor] = useState<string>()
    const [itemColor, setItemColor] = useState<string>()
    const [docColor, setDocColor] = useState<string>()
    const [basketColor, setBasketColor] = useState<string>()
    const [evolisColor, setEvolisColor] = useState<string>()
    const [adminColor, setAdminColor] = useState<string>()

    const addSystemToBasket = () => {
        if (basket == undefined) {
            setBasket(1)
        }
        else {
            setBasket(basket + 1)
        }
    }

    const removeSystemFromBasket = () => {
        setBasket(basket! - 1)
    }

    const getProjectDetails = async () => {
        try {
            setLoading(true)
            const response = await API.get(`/projects/${params.id}`)
            if (response.status === 200) {
                setProject(response.data)
                setLoading(false)
            }
        } catch (error) {
            add({
                message: 'Failed to get document details!',
                severity: "error"
            }, 2000)
        }
    }

    const getSystems = async () => {
        try {
            const response = await API.get(`/projects/${params.id}/users/${User.id}/systems`)
            if (response.status === 200 || response.status === 201) {

                setBasket(response.data.length)

            }
        } catch (error) {
            add({
                message: 'Failed to get project systems!',
                severity: "error"
            }, 2000)
        }
    }

    useEffect(() => {
        getProjectDetails()
        getSystems()
    }, [])

    useEffect(() => {
        if (view == 'systems') {
            setSystemColor("#2BD2FF")
        }
        else {
            setSystemColor("#CBD5E1")
        }
        if (view == 'items') {
            setItemColor("#2BD2FF")
        }
        else {
            setItemColor("#CBD5E1")
        }
        if (view == 'documents') {
            setDocColor("#2BD2FF")
        }
        else {
            setDocColor("#CBD5E1")
        }
        if (view == 'basket') {
            setBasketColor("#2BD2FF")
        }
        else {
            setBasketColor("#CBD5E1")
        }
        if (view == 'evolis') {
            setEvolisColor("#2BD2FF")
        }
        else {
            setEvolisColor("#CBD5E1")
        }
        if (view == 'admin') {
            setAdminColor("#2BD2FF")
        }
        else {
            setAdminColor("#CBD5E1")
        }
    })

    let content = null

    const view = query.get('view')

    if (view == 'systems') content = <Systems addSystemToBasket={addSystemToBasket} removeSystemFromBasket={removeSystemFromBasket} project_id={params.id} />
    if (view == 'items') content = <Items project_id={params.id} />
    if (view == 'documents') content = <Documents project_id={params.id} />
    if (view == 'evolis') content = <Evolis project_id={params.id} />
    if (view == 'basket') content = <Basket removeSystemFromBasket={removeSystemFromBasket} project_id={params.id} />
    if (view == 'admin') content = <Admin project_id={params.id} />

    const li_class = "h-12 text-center text-slate-300 relative"

    return (
        <div className="h-full w-full flex flex-col overflow-hidden">
            <div className="flex flex-shrink flex-grow">
                <div className="flex flex-col bg-white text-black p-2">
                    <div className="">

                    </div>
                    <Link to={`/`} className="">
                        <img className="h-12 w-11 ml-1" src={appLogoNew} alt="Workflow" />
                    </Link>
                    <div className="flex ml-1">
                        <AppDropdown
                            items={[{
                                text: "",
                                icon: <FiLogOut onClick={e => logout()} />
                            }]} >
                            <div className="flex flex-col w-100 mt-1">
                                <img className="h-9 w-9 m-1" src={UserIcon} alt="User icon" />
                            </div>
                        </AppDropdown>
                    </div>
                    <div className="flex-grow flex">
                        <ul className="self-center">
                            <Link to={location.pathname + '?view=systems'}><li className={li_class}><FaCubes color={systemColor} size={30} className="ml-4" /></li></Link>
                            <Link to={location.pathname + '?view=items'}><li className={li_class}><BiCube color={itemColor} size={30} className="ml-4" /></li></Link>
                            <Link to={location.pathname + '?view=documents'}><li className={li_class}><AiOutlineFileSearch color={docColor} size={30} className="ml-4" /></li></Link>
                            <Link to={location.pathname + '?view=basket'}><li className={li_class}><BsBasket3 color={basketColor} size={30} className="inline-block ml-4" /> <div className="inline-block text-red-500">{basket}</div> </li></Link>
                        </ul>
                    </div>
                    <div className="">
                        <ul className="self-center">
                            <Link to={location.pathname + '?view=evolis'}><li className={li_class}><LogoEvolis className="h-8 w-8 ml-4" fill={evolisColor} /></li></Link>
                            {User.isAdmin && <Link to={location.pathname + '?view=admin'}><li className={li_class}><RiTableLine color={adminColor} size={30} className="ml-4" /></li></Link>}
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col flex-grow overflow-auto">
                    <div className="flex-shrink flex-grow" >
                        <Loader isLoading={isLoading}>
                            {content}
                        </Loader>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Document