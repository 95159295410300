import { Component } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AgGridEvent, CellEvent, ColumnApi, GridApi } from "ag-grid-community";

import Excel from '@utils/functions/excel'

import ContextMenu from '@components/contextmenu'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { add } from "@services/NotificationService";
import { User } from '@app/services/UserService';

import API from '@services/ApiService';
import { Partial, ISystem } from '@utils/types'

import { Dropdown as DropdownRenderer, Button as ButtonRenderer, Comment as CommentRenderer } from '@components/grids/renderers'

import { contextMenu } from 'react-contexify'

const FileSaver = require('file-saver');

interface IGridProps {
    systems?: ISystem[]
    project_id: string
    contextMenu?: typeof contextMenu
}

interface IGridState {
    showDetails: boolean
    showComment: boolean
    numberOfRow: number
    numberOfSelectedRow: number
    data: ISystem[]
}

export default class Grid extends Component<IGridProps, IGridState> {

    gridApi?: GridApi
    columnApi?: ColumnApi

    constructor(props: IGridProps) {
        super(props)
        this.state = {
            showDetails: false,
            showComment: false,
            numberOfRow: 0,
            numberOfSelectedRow: 0,
            data: []
        }

        this.getSystems()
    }

    async getSystems() {
        try {
            const response = await API.get(`/projects/${this.props.project_id}/systems`)
            if (response.status !== 200) return

            const data = response.data
            this.setState({ data })

        } catch (error) {
            add({
                message: 'Failed to get project systems!',
                severity: "error"
            }, 2000)
        }
    }

    async generateFileToExportAndPdf(fileNameToExport: "M_IDVW" | "M_IDWG") {

        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        let zipName
        let url

        if (fileNameToExport == "M_IDVW") {
            zipName = "OVERVIEWS.zip"
            url = `/projects/${this.props.project_id}/systems/overviews/download`
        }
        else {
            zipName = "DRAWINGS.zip"
            url = `/projects/${this.props.project_id}/items/drawings/download`

            await this.exportPdfData()
        }

        const response = await API.post(url, tags, { responseType: 'arraybuffer' })
        if (response.status == 200) {
            var blob = new Blob([response.data], { type: "application/zip" })
            FileSaver.saveAs(blob, zipName);
        }

        const responseExcel = await API.post(`/projects/${this.props.project_id}/${fileNameToExport}`, tags)
        if (responseExcel.status == 200) Excel.download({ [fileNameToExport]: responseExcel.data }, fileNameToExport + '.xlsx')
    }

    async generateFileToExport(fileNameToExport: string) {
        if (!this.gridApi) return []
        let selectedNodes = this.gridApi.getSelectedNodes();
        const tags = selectedNodes.map(node => node.data.tag);

        const response = await API.post(`/projects/${this.props.project_id}/${fileNameToExport}`, tags)
        if (response.status == 200) Excel.download({ [fileNameToExport]: response.data }, fileNameToExport + '.xlsx')
    }

    async exportPdfData() {
        const response = await API.get(`projects/${this.props.project_id}/items/drawings/getDatas`)
        if (response.status == 200) Excel.download({ I_GRID: response.data }, `I_GRID.xlsx`)
    }

    getMenu() {
        const menu: any[] = [{
            type: 'sub',
            text: 'Generate',
            children: [{
                type: 'item',
                text: 'M_IDVW',
                onClick: this.generateFileToExportAndPdf.bind(this, "M_IDVW")
            },
            {
                type: 'item',
                text: 'M_FLOC1',
                onClick: this.generateFileToExport.bind(this, "M_FLOC1")
            },
            {
                type: 'item',
                text: 'M_FLOC2',
                onClick: this.generateFileToExport.bind(this, "M_FLOC2")
            },
            {
                type: 'item',
                text: 'M_INTS',
                onClick: this.generateFileToExport.bind(this, "M_INTS")
            },
            {
                type: 'item',
                text: 'F_DOCS',
                onClick: this.generateFileToExport.bind(this, "F_DOCS")
            },
            {
                type: 'item',
                text: 'M_IDWG + I_GRID',
                onClick: this.generateFileToExportAndPdf.bind(this, "M_IDWG")
            },
            {
                type: 'item',
                text: 'I_FLOC',
                onClick: this.generateFileToExport.bind(this, "I_FLOC")
            },
            ]
        }]
        return menu
    }

    render() {

        const defaultColDef = {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] },
            cellStyle: { fontSize: '11px' }
        };

        const menu = this.getMenu()

        return (
            <div className="ag-theme-material h-full w-full">

                <ContextMenu menu={menu} MENU_ID='SYS_CTM'></ContextMenu>
                <AgGridReact
                    rowData={this.state.data}
                    defaultColDef={defaultColDef}
                    suppressDragLeaveHidesColumns={true}
                    rowSelection='multiple'
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    frameworkComponents={{
                        DropdownRenderer,
                        ButtonRenderer,
                        CommentRenderer
                    }}
                    getRowNodeId={(data: any) => {
                        return data.tag;
                    }}
                    onGridReady={this.onGridReady.bind(this)}
                    onFirstDataRendered={this.onGridReady.bind(this)}
                    onCellContextMenu={this.contextMenu}
                    preventDefaultOnContextMenu={true}
                    onColumnVisible={this.saveState.bind(this)}
                    onColumnPinned={this.saveState.bind(this)}
                    onColumnResized={this.saveState.bind(this)}
                    onDragStopped={this.saveState.bind(this)}
                    onSortChanged={this.saveState.bind(this)}
                    onColumnValueChanged={this.saveState.bind(this)} // A value column was added or removed.
                    onFilterChanged={this.saveFilter.bind(this)}
                >
                    <AgGridColumn field="site" headerName="SITE" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="sector" headerName="SECTOR" floatingFilter={true}
                        editable={User.isAdmin}
                        onCellValueChanged={(params: any) => {
                            var system: ISystem = params.data
                            this.updateSystem(system, system)
                        }} />
                    <AgGridColumn field="tag" headerName="TAG" floatingFilter={true} />
                    <AgGridColumn field="technical_class.class.name" headerName="CLASS" floatingFilter={true} />
                    <AgGridColumn field="technicalClassName" headerName="TECHNICAL CLASS" floatingFilter={true} />
                    <AgGridColumn field="description" headerName="DESCRIPTION" floatingFilter={true} />
                    <AgGridColumn field="inspection_type" headerName="INSPECTION TYPE"
                        floatingFilter={true}
                        maxWidth={150}
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'ISO',
                                'P&ID',
                                '-'
                            ]
                        }} />
                    <AgGridColumn field="scheduling_tag" headerName="SCHEDULING TAG" floatingFilter={true} />
                    <AgGridColumn field="navisworks_status" floatingFilter={true}
                        headerName="3D PREPARATION"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'HOLD',
                                'INITIATED',
                                'PROCESSING',
                                'TO CHECK',
                                'TO APPROVE',
                                'DONE',
                                'N/A'
                            ]
                        }}
                        minWidth={170}
                        resizable={false} />
                    <AgGridColumn field="overview_status"
                        floatingFilter={true}
                        headerName="OVERVIEW"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            disabled: false,
                            values: [
                                'HOLD',
                                'INITIATED',
                                'PROCESSING',
                                'TO CHECK',
                                'TO APPROVE',
                                'DONE',
                                'N/A'
                            ],
                        }}
                        minWidth={160}
                        resizable={false} />
                    <AgGridColumn field="isometrics_status"
                        floatingFilter={true}
                        headerName="ISOMETRICS"
                        cellRenderer="DropdownRenderer"
                        cellRendererParams={{
                            values: [
                                'HOLD',
                                'INITIATED',
                                'PROCESSING',
                                'TO CHECK',
                                'TO APPROVE',
                                'DONE',
                                'N/A'
                            ],
                        }}
                        minWidth={160}
                        resizable={false} />
                </AgGridReact>

                <div className='bg-white h-10'>
                    <div className='flex'>
                        <h1 className='m-2 text-sm font-semibold text-gray-300'>Total : {this.state.numberOfRow}</h1>
                        <h1 className='m-2 text-sm font-bold text-gray-400'>Rows selected : {this.state.numberOfSelectedRow}</h1>
                    </div>
                </div>
            </div>
        );
    }

    contextMenu(data: CellEvent) {

        if (!data.node.isSelected()) data.node.setSelected(true, true)

        contextMenu.show({
            id: 'SYS_CTM',
            event: data.event as any
        });
    }

    autoSizeAll(skipHeader: boolean = false): void {
        if (this.columnApi) {
            const allColumnIds: string[] = [];
            const columns = this.columnApi.getAllColumns()
            if (columns) columns.forEach((column) => {
                allColumnIds.push(column.getId());
            });
            this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    }

    onGridReady(params: AgGridEvent): void {
        // or setState if using components
        this.gridApi = params.api
        this.columnApi = params.columnApi
        // this.autoSizeAll(false);
        this.gridApi.sizeColumnsToFit();

        const numberOfRow = this.gridApi.getDisplayedRowCount()
        this.setState({ numberOfRow })

        this.restoreState()
    }

    onSelectionChanged = (event: any) => {
        var rowCount = event.api.getSelectedNodes().length;
        this.setState({ numberOfSelectedRow: rowCount })
    };

    saveState() {
        if (!this.columnApi) return;
        let state = this.columnApi?.getColumnState();
        localStorage.setItem('systemTableState', JSON.stringify(state));
    }

    saveFilter() {
        if (!this.gridApi) return;
        let filter = this.gridApi.getFilterModel();
        localStorage.setItem('systemTableState', JSON.stringify(filter));
    }

    restoreState() {
        //STATE
        let data = localStorage.getItem('systemTableState')
        if (data) {
            let state = JSON.parse(data)
            this.columnApi?.applyColumnState({ state })
        }
    }

    async updateSystem(system: ISystem, patch_data: Partial<ISystem>) {
        try {
            const response = await API.patch(`/projects/${this.props.project_id}/systems/${system.id}`, patch_data)
            if (response.status === 200) {
                const rowNode = this.gridApi?.getRowNode(system.id.toString());
                rowNode?.setData({ ...rowNode?.data, ...patch_data });
            }
        } catch (error) {
            add({
                message: `Failed to update project system id: ${system.id}!`,
                severity: "error"
            }, 2000)
        }
    }
}
