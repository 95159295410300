import { ChangeEvent, Component } from 'react'

import API from '@services/ApiService'
import { IUser } from '@utils/types'

import Loader from '@components/common/Loader'
import { IProject } from '@utils/types'

import notifications from '@services/NotificationService'

enum TYPES {
    'D',
    'M',
    'C'
}

const types = {
    [TYPES.C]: 'Create',
    [TYPES.M]: 'Modify',
    [TYPES.D]: 'Delete'
}

export default class UserForm extends Component<{ data?: IUser, update?: boolean, projects?: IProject[], callback?: (user: IUser) => void, delete?: boolean }, {}> {

    constructor(props: any) {
        super(props)
    }

    state = {
        user: this.props.data,
        id: this.props.data?.id,
        username: this.props.data?.username,
        email: this.props.data?.email,
        password: this.props.data?.password,
        isAdmin: this.props.data?.isAdmin,
        update: this.props.update,
        isLoading: false,
        projects: this.props.projects
    }

    type = this.props.data ? this.props.delete ? TYPES.D : TYPES.M : TYPES.C

    async onSubmit(e: any) {
        e.preventDefault()
        this.setState({ isLoading: true })
        const data = new FormData(e.target)

        const username = String(data.get('username'))
        const email = String(data.get('email'))
        const password = String(data.get('password'))
        const isAdmin = Boolean(data.get('isAdmin'))
        const projectId = String(data.get('project'))

        let result

        try {
            if (this.type === TYPES.C) {
                result = await API.post('/users', {
                    username: username,
                    email: email,
                    password: password,
                    isAdmin: isAdmin
                })

            } else if (this.type === TYPES.M) {
                result = await API.put('/users/' + this.props.data?.id, {
                    username: username,
                    email: email,
                    isAdmin: isAdmin
                })

            } else if (this.type === TYPES.D) {
                result = await API.delete('/users/' + this.props.data?.id)
            }
            if (this.props.callback) this.props.callback(result as any)
        } catch (error) {
            
        }

        this.setState({ isLoading: false })
    }

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    assignProjectUser = async (e: any) => {

        this.setState({ isLoading: true })

        if (e.target.checked) {
            const result = await API.post(`/projects/${e.target.id}/users/${this.state.id}`)
            if (result.status == 200 || result.status == 201) {
                notifications.add({
                    message: 'Project Assigned',
                    severity: 'success'
                },2000)
                this.setState({ isLoading: false })
            }
        }
        else {
            const result = await API.delete(`/projects/${e.target.id}/users/${this.state.id}`)
            if (result.status == 200 || result.status == 201) {
                notifications.add({
                    message: 'Project Unassigned',
                    severity: 'success'
                },2000)
                this.setState({ isLoading: false })
            }
        }
    }

    render() {
        return (
            <Loader isLoading={this.state.isLoading}>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <label className="block">
                        <span className="text-white">Username</span>
                        <input className="form-input p-1 block w-full h-8 outline-none font-bold"
                            placeholder="Username"
                            name="username"
                            id='username'
                            value={this.state.username}
                            onChange={this.handleChange.bind(this)}
                            readOnly={this.props.delete}
                            required
                        />
                    </label>
                    <label className="block">
                        <span className="text-white">Email</span><input className="form-input p-1 block w-full h-8 outline-none font-bold"
                            placeholder="Email"
                            name="email"
                            id='email'
                            value={this.state.email}
                            onChange={this.handleChange.bind(this)}
                            readOnly={this.props.delete}
                            required />

                    </label>

                    <label className="block">
                        {this.state.update == true ? "" :
                            <><span className="text-white">Password</span><input className="form-input p-1 block w-full h-8 outline-none font-bold"
                                placeholder="Password"
                                name="password"
                                type="password"
                                id='password'
                                value={this.state.password}
                                onChange={this.handleChange.bind(this)}
                                readOnly={this.props.delete} /></>}
                    </label>

                    <label className="block pt-2 pb-2">
                        <span className="text-white mr-4">isAdmin ?</span>
                        <label className="switch">
                            <input type="checkbox" defaultChecked={this.state.isAdmin == true} name="isAdmin" value="true" />
                            <span className="slider round"></span>
                        </label>
                    </label>

                    <label className="block pt-2 pb-4">
                        {this.state.update ? <span className="text-white mr-4">Attributed projects</span> : ""}
                        {this.state.update ? this.state.projects?.map((project) => {

                            let contain = false;
                            return <div>

                                {project.users?.map(user => {
                                    //Check if user is contained in the project
                                    if (user.id == this.state.id) {
                                        contain = true
                                    }
                                })}
                                <input type="checkbox" id={project.id.toString()} key={project.id.toString()} onClick={this.assignProjectUser} name="project" defaultChecked={contain} value={project.id.toString()} />
                                <label className='ml-2' htmlFor="scales">{project['name']}</label>
                            </div>
                        }) : ""}
                    </label>

                    <div className='grid justify-items-center'>
                        <button className="text-alis-1 
                            bg-transparent border border-solid border-alis-1 hover:bg-alis-1 hover:text-white 
                            active:bg-purple-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none"
                            type="submit" >
                            {types[this.type] + ' user'}
                        </button>
                    </div>
                </form>
            </Loader>
        )
    }
}
