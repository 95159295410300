import { Component, ReactElement } from 'react'

import Systems from './Systems'
import Items from './Items'
import Docs from './Docs'
import Templates from './Templates'
import Association from './Association'

import documents from '@assets/documents.png'
import items from '@assets/items.png'
import link_documents from '@assets/link_documents.png'
import systems from '@assets/systems.png'
import templates from '@assets/templates.png'
import bgNew from '@assets/login_bg_new.jpg'

import { GiDragonBreath as BackIcon } from 'react-icons/gi';
import { GiSpikedDragonHead as DragonIcon } from 'react-icons/gi';

interface IProps {
    project_id: string
}

interface IState {
    display?: ReactElement
}

export default class Admin extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {

        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(){
        this.setState({display : undefined})
    }

    render() {

        const cardClasses = "h-40 w-40 mb-16 text-center border-4"

        return (
            this.state?.display ?
                <div className="p-2 font-system h-full w-full flex flex-col" style={{
                    backgroundImage: "url(" + bgNew + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <button className="flex hover:bg-gray-400 p-2" onClick={() => { this.handleClick() }} >
                        <BackIcon className="h-8 w-8" style={{ transform: 'rotate(135deg)' }} />
                        <DragonIcon className="ml-1 h-7 w-7" style={{ transform: 'rotateY(180deg)' }} />
                    </button>
                    {this.state.display}
                </div>
                :
                <div className="flex h-full font-system" style={{
                    backgroundImage: "url(" + bgNew + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>

                    <div className="flex flex-col flex-grow justify-evenly items-center">
                        <div className='bg-clip-padding p-6 bg-white opacity-80'>
                            <div className={cardClasses} onClick={() => {
                                this.setState({ display: <Templates handleClick={this.handleClick} project_id={this.props.project_id} /> })
                            }}>
                                <img src={templates}></img>
                                <h1 className='text-black mt-8 text-xl'>
                                    TEMPLATES
                                </h1>
                            </div>
                        </div>

                        <div className='bg-clip-padding p-6 bg-white opacity-80'>
                            <div className={cardClasses} onClick={() => {
                                this.setState({ display: <Docs handleClick={this.handleClick} project_id={this.props.project_id} /> })
                            }}>
                                <img src={documents}></img>
                                <h1 className='text-black mt-8 text-xl'>
                                    DOCUMENTS
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-grow justify-evenly items-center">
                        <div className='bg-clip-padding p-6 bg-white opacity-80'>
                            <div className={cardClasses} onClick={() => {
                                this.setState({ display: <Association handleClick={this.handleClick} project_id={this.props.project_id} /> })
                            }}>
                                <img src={link_documents}></img>
                                <h1 className='text-black mt-8 text-xl'>
                                    LINK DOCS
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-grow justify-evenly items-center">
                        <div className='bg-clip-padding p-6 bg-white opacity-80'>
                            <div className={cardClasses} onClick={() => {
                                this.setState({ display: <Systems handleClick={this.handleClick} project_id={this.props.project_id} /> })
                            }}>
                                <img src={systems}></img>
                                <h1 className='text-black mt-8 text-xl'>
                                    SYSTEMS
                                </h1>
                            </div>
                        </div>
                        <div className='bg-clip-padding p-6 bg-white opacity-80'>
                            <div className={cardClasses} onClick={() => {
                                this.setState({ display: <Items handleClick={this.handleClick} project_id={this.props.project_id} /> })
                            }}>
                                <img src={items}></img>
                                <h1 className='text-black mt-8 text-xl'>
                                    ITEMS
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
