import { useState, useEffect } from 'react'

import {
    useHistory
} from "react-router-dom";

import { IProject } from '@utils/types';
import notifications from '@services/NotificationService'
import { User, logout } from '@services/UserService'

import ProjectCard from '@components/ProjectCard'
import ProjectForm from '@components/forms/ProjectForm'
import MainModal from '@components/modal'

import AppInput, { InputType } from '@components/common/AppInput'
import Loader from '@components/common/Loader'

import API from '@services/ApiService'

export default function Projects() {
    const [projects, setProjects] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const [popup, setPopup] = useState<any>(false)

    let history = useHistory();

    async function getProjects() {
        setLoading(true)
        try {
            const response = await API.get(`/users/${User.id}/projects`)
            if (response.status === 200) {
                setProjects(response.data)
            }
        } catch (error) {
            notifications.add({
                message: 'Failed to get projects!',
                severity: 'error'
            },2000)
        }
        setLoading(false)
    }

    useEffect(() => {
        getProjects()
    }, [])

    const filteredProjects = projects.filter((project) => {
        return (project.name.toLowerCase().includes(search.toLowerCase()) || project.description.toLowerCase().includes(search.toLowerCase()))
    })

    const popupContent = {
        create: () => {
            return <ProjectForm callback={(project) => {
                setPopup(false)
                notifications.add({
                    message: 'Project created',
                    severity: 'success'
                },2000)
                getProjects()
            }}></ProjectForm>
        },
        update: (project: IProject) => {
            return <ProjectForm data={project} callback={(project) => {
                setPopup(false)
                notifications.add({
                    message: 'Project Modified',
                    severity: 'success'
                },2000)
                getProjects()
            }}></ProjectForm>
        },
        delete: (project: IProject) => {
            return <ProjectForm data={project} delete={true} callback={(project) => {
                setPopup(false)
                notifications.add({
                    message: 'Project Deleted',
                    severity: 'success'
                },2000)
                getProjects()
            }}></ProjectForm>
        }
    }

    return (
        <div className="h-full w-full flex flex-col text-alis-2 bg-white">
            <div className='flex justify-between'>
                <div className='pl-4'>
                    <span className="inline-block select-none">
                        <AppInput type={InputType.search} name="search" placeholder="Search" onChange={(e) => {
                            setSearch((e.target as HTMLInputElement).value)
                        }} ></AppInput>
                    </span>
                    {/* <SearchIcon className="w-7 h-7 inline-block align-middle cursor-pointer hover:text-alis-3" /> */}
                </div>
                <div className='pt-6 pr-4'>
                    <button className='rounded-full font-bold hover:bg-alis-3 bg-alis-2 h-7 w-28 text-sm text-white' onClick={e => setPopup(popupContent.create())}> Add Project </button>
                </div>
            </div>

            <div className="flex-shrink flex-grow overflow-auto">
                <Loader isLoading={isLoading}>
                    <div className="p-2 h-full flex flex-wrap overflow-auto">
                        {filteredProjects.map((project, index) => {
                            return <ProjectCard project={project} onUpdate={() => {
                                setPopup(popupContent.update(project))
                            }} onDelete={() => {
                                setPopup(popupContent.delete(project))
                            }} />
                        })}

                        {popup &&
                            <MainModal onClose={() => setPopup(false)}>
                                {popup}
                            </MainModal>
                        }
                    </div>
                </Loader>
            </div>
        </div>
    )
}
