import { Component } from 'react'

import Excel from '@utils/functions/excel'

import notifications, { add } from "@services/NotificationService";

import API from '@services/ApiService';

import UploadComponent from './UploadComponent';

interface IProps {
    project_id: string
    handleClick: () => void
}

export default class Items extends Component<IProps, {}> {

    state = {
        uploaded: false
    }

    render() {
        return (
            <div className='flex flex-col justify-center flex-grow'>
                <UploadComponent handleClick={this.props.handleClick} 
                    downloadTemplate={this.downloadItems.bind(this)} 
                    upload={this.importItems.bind(this)} 
                    uploaded={this.state.uploaded} 
                    title="ITEMS"></UploadComponent>
            </div>
        )
    }

    async downloadItems() {
        try {
            const response = await API.get(`/admin/projects/${this.props.project_id}/export/items`)
            if (response.status === 200 || response.status == 201 || response.status == 204) {
                let data = response.data
                Excel.download(data, 'Items export.xlsx')
            }
        } catch (error) {
            add({
                message: 'Failed to get project items!',
                severity: "error"
            },2000)
        }
    }

    async importItems(files: File[]) {
        try {
            const p = []
            for (const i in files) {
                p.push(Excel.parse(files[i]))
            }
            const parsedFiles = await Promise.all<any>(p)
            const body = []
            for (const i in parsedFiles) {
                if (parsedFiles[i].Item) {
                    body.push(...parsedFiles[i].Item)
                }
            }

            const response = await API.post(`/admin/projects/${this.props.project_id}/import/items`, body)
            if (response.status === 200) {
                this.setState({ uploaded: true })

                notifications.add({
                    message: 'Items import success!',
                    severity: 'success'
                },2000)
            }
        } catch (error: any) {

            const data = error.data.data

            let Item: { ItemTag: any; Error: any; }[] = []

            data.map((item: any) => {
                if (item.error == undefined) {}
                else if(item.error[0] == "current transaction is aborted, commands ignored until end of transaction block"){}
                else {
                    var info = {
                        "ItemTag" : item.tag,
                        "Error" : item.error[0]
                    }
                    Item.push(info)
                }
            })

            Excel.download({ItemErrors : Item} , 'Errors.xlsx')

            add({
                message: 'Failed to import project items!',
                severity: "error"
            },2000)
        }
    }
}
