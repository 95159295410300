import { Component } from 'react'
import { BsCheck2 } from 'react-icons/bs'
import { AiOutlineDownload } from 'react-icons/ai';
import Upload from '@components/Upload'

interface IProps {
    handleClick: () => void
    downloadTemplate : () => void
    upload : any
    uploaded : boolean
    title: string
}

export default class UploadComponent extends Component<IProps, {}> {

    render() {
        return (
            <div className="flex self-center items-center justify-center text-center">

                <div className='bg-clip-padding py-10 px-32 bg-white opacity-80 mb-28'>

                    {this.props.uploaded ?
                        <div>
                            <BsCheck2 className='ml-16' color='#2BFF88' size={70}></BsCheck2>
                            <h1>IMPORT SUCCESS</h1>
                            <button className='rounded bg-alis-1 w-52 text-white p-1 mt-4' onClick={this.props.handleClick}> GO BACK ADMIN PANEL </button>
                        </div>

                        :

                        <div>
                            <div className='flex justify-between'>
                                <button><AiOutlineDownload size={25} className='text-black' onClick={this.props.downloadTemplate.bind(this)}></AiOutlineDownload></button>
                                <button onClick={this.props.handleClick} className='text-black'>X</button>
                            </div>

                            <div className="flex-grow h-full w-96">
                                <h1 className='pb-2'>UPLOAD {this.props.title}</h1>
                                <Upload uploadHandler={this.props.upload.bind(this)} multiple={true} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
