import API from '@app/services/ApiService';
import { Component } from 'react'
import { ISector } from '@utils/types';
import notifications from '@app/services/NotificationService';
import Excel from '@utils/functions/excel'

import { RiDeleteBin7Fill } from 'react-icons/ri';
import { BsCheckLg } from 'react-icons/bs';


interface IProps {
    project_id: string
}

interface IState {
    sectors: ISector[]
}

export default class SectorTemplate extends Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.state = {
            sectors: [{ site_reference: "", ev_reference: "", ev_description: "", ev_filename: "" }],
        };
        this.updateSector = this.updateSector.bind(this);
    }

    async getSectors() {
        const response = await API.get(`projects/${this.props.project_id}/sectors`)
        if (response.status == 200)
            if (response.data.length >= 1) this.setState({ sectors: response.data })
    }

    addSector() {
        const newGrid = { site_reference: "", ev_reference: "", ev_description: "", ev_filename: "" }
        const sectors = [
            ...this.state.sectors,
            newGrid
        ]
        this.setState({ sectors });
    }

    async deleteSector(ev_reference: string) {
        const response = await API.delete(`projects/${this.props.project_id}/sectors`, { data: { ev_reference: ev_reference } })
        if (response.status == 204) {
            //Reset state
            this.setState({ sectors: [] })
            //Get sectors after the delete
            this.getSectors()
            notifications.add({
                message: "Sector Deleted",
                severity: "success"
            }, 2000)
        }
    }

    async updateSector(e: any) {

        e.preventDefault()
        const data = new FormData(e.target)

        const site_reference = String(data.get('site_reference'))
        const ev_reference = String(data.get('ev_reference'))
        const ev_description = String(data.get('ev_description'))
        const ev_filename = String(data.get('ev_filename'))

        if (this.state.sectors.some(sector => sector.ev_reference == ev_reference)) {
            const response = await API.patch(`projects/${this.props.project_id}/sectors/${ev_reference}`, {
                site_reference: site_reference,
                ev_reference: ev_reference,
                ev_description: ev_description,
                ev_filename: ev_filename
            })
            if (response.status == 200)
                notifications.add({
                    message: "Sector updated",
                    severity: "success"
                }, 2000)
        } else {
            const response = await API.post(`projects/${this.props.project_id}/sectors`, {
                site_reference: site_reference,
                ev_reference: ev_reference,
                ev_description: ev_description,
                ev_filename: ev_filename
            })
            if (response.status == 200)
                this.setState({ sectors: response.data })
            notifications.add({
                message: "Sector created",
                severity: "success"
            }, 2000)
        }
    }

    async downloadData() {
        const response = await API.get(`projects/${this.props.project_id}/sectors`)
        if (response.status == 200)
            Excel.download({ M_SECTOR: response.data }, 'M_SECTOR.xlsx')
    }

    render() {
        return (
            <div className='font-normal'>
                {this.state.sectors.map((sector: any) => {
                    return (
                        <form onSubmit={this.updateSector.bind(this)}>
                            <div className="grid grid-cols-6">
                                <input className="  border-2 border-double border-zinc-300"
                                    placeholder="Site Reference"
                                    name="site_reference"
                                    defaultValue={this.state.sectors[0].site_reference}
                                    required />
                                <input className=" border-2 border-double border-zinc-300"
                                    placeholder="Ev Reference"
                                    name="ev_reference"
                                    defaultValue={sector.ev_reference}
                                    required />
                                <input className=" border-2 border-double border-zinc-300"
                                    placeholder="Ev Description"
                                    name="ev_description"
                                    defaultValue={sector.ev_description}
                                    required />
                                <input className=" border-2 border-double border-zinc-300"
                                    placeholder="Ev Filename"
                                    name="ev_filename"
                                    defaultValue={sector.ev_filename}
                                />
                                <div className='grid grid-cols-2 justify-items-center'>
                                    <button type="submit">
                                        <div className="w-8 h-8 bg-alis-1 rounded-full flex justify-center items-center p-2">
                                            <BsCheckLg color='white' size={25}></BsCheckLg>
                                        </div>
                                    </button>

                                    <button type='button' onClick={() => this.deleteSector(sector.ev_reference)}>
                                        <div className="w-8 h-8 bg-alis-2 rounded-full flex justify-center items-center p-2">
                                            <RiDeleteBin7Fill color='white' size={25}></RiDeleteBin7Fill>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>)
                })}

                <div className='grid grid-cols-2 justify-items-center'>
                    <button className='bg-alis-2 font-semibold mt-2 rounded-lg text-white px-2' onClick={() => this.addSector()}>Add row</button>
                    <button className='bg-alis-3 font-semibold mt-2 rounded-lg text-white px-2' onClick={() => this.downloadData()}>Export Data</button>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.getSectors()
    }
}
