import { INotification } from '@utils/types'

import globalState from '@utils/classes/globalState'

export const { getState, setState, subscribe } = new globalState<INotification[]>([])

export const add = (notification: INotification, time? : number) => {
  const notifications = getState().concat(notification);
  setState(notifications)

  if(time)
    window.setTimeout(remove, time);
}

export const remove = (index: number) => {
  const notifications = getState()
  notifications.splice(index, 1);
  setState(notifications)
}

export const loading = new globalState<boolean>(false)

const notifications = { getState, subscribe, add, remove, loading }

export default notifications