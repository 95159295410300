import { Component } from 'react'
import Modal from './Modal';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import "react-contexify/dist/ReactContexify.css";

import { IItem, ISystem, IUser } from '@utils/types'

import API from '@services/ApiService';
import notifications from '@app/services/NotificationService';

interface IProps {
    onClose: (data?: IItem | ISystem) => void
    data: IItem | ISystem
    type: 'items' | 'systems'
    user: IUser
    project_id: string
}

interface IState {
    comment?: string
}

export default class ModalComment extends Component<IProps, IState> {
    state = {
        comment: this.props.data.comment
    }

    async updateComment(e: any) {
        e.preventDefault();

        try {

            let comment

            if(this.state.comment == "") comment = null
            else comment = this.state.comment

            const result = await API.patch(`/projects/${this.props.project_id}/${this.props.type}/${this.props.data.tag}`, {
                comment: comment
            })

            if (result.status == 200 || result.status == 201) {
                notifications.add({
                    message: 'Comment updated',
                    severity: 'success'
                },2000)
                this.props.data.comment = this.state.comment
                this.props.onClose(this.props.data)
            }
        } catch (error) {
            
        }
    }
    render() {
        return (
            <Modal handleClose={this.props.onClose}>
                <div className='relative bg-white self-center justify-self-center w-80'>
                    <div className='bg-[#ededed] pb-4 pl-4 pt-4'>
                        <div className='grid justify-items-center'>
                            <h1 className='font-bold text-black'>{this.props.data?.tag}</h1>
                            <h1 className='font-bold text-xl text-alis-2'>COMMENT</h1>
                        </div>
                    </div>

                    <div className='pt-4 pb-4 pl-4 pr-4'>
                        <textarea id="commentText" onChange={e => this.setState({ comment: e.target.value })}
                            className='text-l text-base max-h-96 w-full outline outline-offset-2 outline-2 outline-alis-2'
                            name="commentText"
                            value={this.state.comment} />

                        <div className='grid justify-items-center'>

                            <button onClick={this.updateComment.bind(this)} id="submitCreate" className='bg-alis-2 hover:bg-alis-3 text-white font-bold py-2 px-4 rounded mt-2'>
                                Apply comment
                            </button>

                        </div>
                    </div>
                </div>

            </Modal>
        )
    }
}



