import React, { Component } from 'react'
import Modal from '@app/components/modal/Modal';
import ModalDocs from '@components/modal/ModalDocs';
import ModalPdf from '@components/modal/ModalPdf';

import ItemsGrid from '@components/grids/ItemsGrid'
import DocumentsGrid from '@components/grids/DocumentsGrid'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "react-contexify/dist/ReactContexify.css";

import { IDictionary, IDocument, IItem, IProject, ISystem } from '@utils/types'
import API from '@services/ApiService';

import { User } from '@services/UserService';
import { add } from "@services/NotificationService";

interface IProps {
    onClose: () => void
    system: ISystem
    project: IProject
}

interface IState {
    showModalDocs: boolean
    showModalPdf: boolean
    items: IItem[]
    docs: IDocument[]
    overviewPdf: boolean
}
export default class ModalSystem extends Component<IProps, IState> {

    documentsGrid = React.createRef<DocumentsGrid>()
    state = {
        items: [],
        showModalDocs: false,
        docs: [],
        content: undefined,
        overviewPdf: false,
        showModalPdf: false
    }

    colors: IDictionary<string> = {
        'N/A': "#7f7f7f",
        'HOLD': '#f2f2f2',
        'INITIATED': '#ffbd49',
        'PROCESSING': '#ffe53b',
        'TO CHECK': '#50c6ff',
        'TO APPROVE': '#dc96ff',
        'DONE': '#98FB98',
    }

    //Retrieve all the items contained in a system
    async getItems() {
        const response = await API.get(`/projects/${this.props.project.id}/systems/${this.props.system.tag}/items`)
        if (response.status === 200 || response.status === 201) {
            const items = response.data
            this.setState({ items })
        }
    }

    //Determine the percentage of completion from item's contained in selected system
    percentageCalcItems() {

        let count = 0;

        if (this.state.items == null) return

        //Check if attribute is finished from all the items of the selected system
        this.state.items.map((item: IItem) => {
            if (item.status == "FINISHED") count++
        })

        let percentageCompleted = count * 100;
        percentageCompleted = percentageCompleted / this.state.items.length

        return percentageCompleted.toFixed(0);
    }

    copyToClipboard(textToCopy: string) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            add({
                message: 'Copied !',
                severity: "success",
            }, 2000)
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            add({
                message: 'Copied !',
                severity: "success"
            }, 2000)
            return new Promise<void>((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    async checkIfPdfExists() {
        const response = await API.get(`/projects/${this.props.project.id}/systems/${this.props.system.tag}/overviews/checkPdfIfExist`)
        if (response.status == 200) {
            this.setState({ overviewPdf: true })
        }
    }

    async generatePdf() {
        const response = await API.post(`/projects/${this.props.project.id}/systems/overviews/pdf`, [this.props.system.tag])
        if (response.status === 200) {
            add({
                severity: 'success',
                message: response.data.length + 'PDF Generated'
            }, 2000)
        }
    }

    onCloseModalDocs() {
        this.setState({ showModalDocs: false })
        this.documentsGrid.current?.getDocuments()
    }

    onOpenModalPdf() {
        this.setState({ showModalPdf: true })
    }

    onCloseModalPdf() {
        this.setState({ showModalPdf: false })
    }

    render() {
        return (
            <div>
                <Modal handleClose={this.props.onClose}>
                    <div className='bg-white w-7/12 h-full flex flex-col absolute right-0'>
                        <div className='bg-[#f9f9f9] p-4'>
                            <div className='flex'>
                                <div className=''>
                                    <button onClick={() => this.copyToClipboard(`\\\\192.168.1.62\\800_isis_files\\${this.props.project.name}\\systems\\${this.props.system.tag}`)} className='rounded-full font-bold hover:bg-alis-2 bg-alis-3 h-7 w-20 text-xs text-white'>
                                        Copy path</button>
                                </div>
                                <div className='flex-1'>
                                    <h1 className='font-bold text-lg text-center mt-3 text-alis-2 font-blod'>{this.props.system.tag}</h1>
                                </div>
                            </div>

                            <div className='flex overflow-auto justify-center mt-2 font-bold text-sm pl-20'>
                                <div className='text-center p-4'>
                                    <h1 className='mb-2'>3D PREPARATION</h1>
                                    <button disabled style={{ backgroundColor: this.colors[this.props.system.navisworks_status] }} className='rounded-full h-7 w-32'>{this.props.system.navisworks_status}</button>
                                </div>

                                <div className='text-center p-4'>
                                    <h1 className='mb-2'>OVERVIEW</h1>
                                    <button disabled style={{ backgroundColor: this.colors[this.props.system.overview_status] }} className='rounded-full h-7 w-32'>{this.props.system.overview_status}</button>
                                </div>

                                <div className='text-center p-4'>
                                    <h1 className='mb-2'>ISOMETRICS</h1>
                                    <button disabled style={{ backgroundColor: this.colors[this.props.system.isometrics_status] }} className='rounded-full h-7 w-32'>{this.props.system.isometrics_status}</button>
                                </div>
                            </div>
                        </div>

                        <div className='h-full p-4 overflow-auto flex flex-col'>
                            <div className='flex'>
                                <h1 className='font-bold text-xl text-alis-2'> OVERVIEW </h1>
                                {this.state.overviewPdf ?
                                    <button className='rounded-full font-bold hover:bg-alis-2 bg-alis-3 h-7 w-20 text-xs text-white mb-2 ml-2' onClick={() => this.onOpenModalPdf()}>
                                        Open PDF </button>
                                    : <button className='rounded-full font-bold hover:bg-alis-2 bg-alis-3 h-7 w-28 text-xs text-white mb-2 ml-2' onClick={() => this.generatePdf()}>
                                        Generate PDF </button>}
                            </div>

                            <div className="flex flex-col h-4/6 mt-4">
                                <h1 className='font-bold text-xl pb-2 text-alis-2'>ASSOCIATED ITEMS</h1>
                                <div className='flex-1'>
                                    {this.props.system ? <ItemsGrid project_id={this.props.project.id.toString()} system={this.props.system} /> : ""}
                                </div>
                            </div>

                            <div className='flex flex-col h-96 mt-10 text-alis-2'>
                                <div className='flex'>
                                    <h1 className='font-bold text-xl'> ASSOCIATED DOC </h1>
                                    {(User.isAdmin || this.props.system.user == User.username) &&
                                        <button className='rounded-full font-bold hover:bg-alis-2 bg-alis-3 h-7 w-20 text-xs text-white mb-2 ml-2'
                                            onClick={() => this.setState({ showModalDocs: true })}> Add Doc </button>}
                                </div>
                                {this.props.system &&
                                    <DocumentsGrid ref={this.documentsGrid} system={this.props.system}
                                        destination={"deleteFromSystem"}
                                        hideColumn={true}
                                        project_id={this.props.project.id.toString()} />
                                }
                            </div>
                        </div>
                    </div>
                </Modal>

                {this.state.showModalDocs &&
                    <ModalDocs
                        hideModal={() => this.onCloseModalDocs()}
                        project_id={this.props.project.id.toString()}
                        system={this.props.system}
                    />}

                {this.state.showModalPdf &&
                    <ModalPdf
                        hideModal={() => this.onCloseModalPdf()}
                        docType="OVW"
                        project_id={this.props.project.id.toString()}
                        tag={this.props.system.tag}
                    />}
            </div>
        )
    }

    componentDidMount() {
        this.getItems()
        this.checkIfPdfExists()
    }
}