import { useState, useEffect } from 'react'

import {
    useHistory
} from "react-router-dom";

import { IUser } from '@utils/types';
import notifications from '@services/NotificationService'
import { User, logout } from '@services/UserService'

import UserCard from '@components/UserCard'
import UserForm from '@components/forms/UserForm'
import MainModal from '@components/modal'

import AppInput, { InputType } from '@components/common/AppInput'
import Loader from '@components/common/Loader'

import API from '@services/ApiService'

export default function Users() {
    const [users, setUsers] = useState<any[]>([])
    const [projects, setProjects] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const [popup, setPopup] = useState<any>(false)

    let history = useHistory();

    async function getProjects() {
        const response = await API.get('/projects')
        if (response.status === 200) {
            setProjects(response.data);
        }
    }

    async function getUsers() {
        setLoading(true)
        try {
            const response = await API.get('/users')
            if (response.status === 200) {
                setUsers(response.data)
            }
        } catch (error) {
            notifications.add({
                message: 'Failed to get users!',
                severity: 'error'
            },2000)
        }
        setLoading(false)
    }

    useEffect(() => {
        getUsers()
        getProjects()
    }, [])

    const filteredUsers = users.filter((user) => {
        return (user.username.toLowerCase().includes(search.toLowerCase()))
    })

    const items = []

    if (User.isAdmin) {
        items.push({
            text: 'Admin',
            onClick: () => history.push('/admin')
        })
    }

    items.push({
        text: 'Disconnect',
        onClick: () => logout()
    })

    const popupContent = {
        create: () => {
            return <UserForm projects={projects} callback={(user) => {
                setPopup(false)
                notifications.add({
                    message: 'User created',
                    severity: 'success'
                },2000)
                getUsers()
            }}></UserForm>
        },
        update: (user: IUser) => {
            return <UserForm data={user} update={true} projects={projects} callback={(user) => {
                setPopup(false)
                notifications.add({
                    message: 'User Modified',
                    severity: 'success'
                },2000)
                getUsers()
                getProjects()
            }}></UserForm>
        },
        delete: (user: IUser) => {
            return <UserForm data={user} delete={true} callback={(user) => {
                setPopup(false)
                notifications.add({
                    message: 'User Deleted',
                    severity: 'success'
                },2000)
                getUsers()
            }}></UserForm>
        }
    }

    return (
        <div className="h-full w-full flex flex-col text-alis-2 bg-white">
            <div>
                <div className='flex justify-between'>
                    <div className='pl-4'>
                        <span className="inline-block select-none">
                            <AppInput type={InputType.search} name="search" placeholder="Search" onChange={(e) => {
                                setSearch((e.target as HTMLInputElement).value)
                            }} ></AppInput>
                        </span>
                    </div>
                    <div className='pt-6 pr-4'>
                        <button className='rounded-full font-bold hover:bg-alis-3 bg-alis-2 h-7 w-28 text-sm text-white' onClick={e => setPopup(popupContent.create())}> Add User </button>
                    </div>
                </div>
            </div>

            <div className="flex-shrink flex-grow overflow-auto">
                <Loader isLoading={isLoading}>
                    <div className="p-2 h-full flex flex-wrap overflow-auto">
                        {filteredUsers.map((user, index) => {
                            return <UserCard user={user} onUpdate={() => {
                                setPopup(popupContent.update(user))
                            }} onDelete={() => {
                                setPopup(popupContent.delete(user))
                            }} />
                        })}

                        {popup &&
                            <MainModal onClose={() => setPopup(false)}>
                                {popup}
                            </MainModal>
                        }
                    </div>
                </Loader>
            </div>
        </div>
    )
}
