import { Component, FormEvent } from 'react'
import { colors} from '@utils/const';

interface props{
    name: string,
    type: InputType,
    label?: string,
    placeholder?: string,
    className?: string,
    value?: string,
    onChange?: (e: FormEvent<HTMLInputElement>) => void
}

export enum InputType {
    button = "button",
    checkbox = "checkbox",
    color = "color",
    date = "date",
    datetimelocal = "datetime-local",
    email = "email",
    file = "file",
    hidden = "hidden",
    image = "image",
    month = "month",
    number = "number",
    password = "password",
    radio = "radio",
    range = "range",
    reset = "reset",
    search = "search",
    submit = "submit",
    tel = "tel",
    text = "text",
    time = "time",
    url = "url",
    week = "week"
};

export default class AppInput extends Component <props, {}> {
    render() {
        return (
            <div className={`w-full relative mb-4 pb-0.5 text-alis-2 ${colors.mainGradient} ${colors.hoverGradient}`}>
                {this.props.label ? <label className="absolute w-full text-center cursor-text text-sm" htmlFor={this.props.name}>{this.props.label}</label> : null} 
                <input className={`w-full pt-6 bg-white hover:bg-opacity-90 text-alis-2 rounded-none outline-none text-center text-md shadow-none
                    ${this.props.className}`}
                    type={this.props.type} 
                    placeholder={this.props.placeholder} 
                    name={this.props.name} 
                    id={this.props.name}
                    spellCheck="false"
                    defaultValue={this.props.value || ''}
                    onChange={this.props.onChange} />
            </div>
        )
    }
}
