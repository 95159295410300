import API from '@app/services/ApiService';
import notifications from '@app/services/NotificationService';
import { IGrid, ISector } from '@app/utils/types';
import { Component } from 'react'
import Excel from '@utils/functions/excel'
import { RiDeleteBin7Fill } from 'react-icons/ri';
import { BsCheckLg } from 'react-icons/bs';

interface IProps {
    project_id: string
}

interface IState {
    grids: IGrid[]
    sectors: ISector[]
}

export default class GridTemplate extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            grids: [
                { ev_definition: "", ev_description: "", ev_level: "", ev_grid: "", ev_locationDrawingFilename: "" },
            ],
            sectors: [{ site_reference: "", ev_reference: "", ev_description: "", ev_filename: "" }]
        };
    }

    async getSectors() {
        const response = await API.get(`projects/${this.props.project_id}/sectors`)
        if (response.status == 200)
            this.setState({ sectors: response.data })
    }

    async getGrids() {
        const response = await API.get(`/projects/${this.props.project_id}/grids`)
        if (response.status = 200 || response.status == 201) {
            if (response.data.length < 1) { }
            else
                this.setState({ grids: response.data })
        }
    }

    addGrid() {
        const newGrid = { ev_definition: "", ev_description: "", ev_level: "", ev_grid: "", ev_locationDrawingFilename: "" }
        const grids = [
            ...this.state.grids,
            newGrid
        ]
        this.setState({ grids });
    }

    async updateGrid(e: any) {
        e.preventDefault()
        const data = new FormData(e.target)
        const ev_definition = String(data.get('ev_definition'))
        const ev_description = String(data.get('ev_description'))
        const ev_site = String(data.get('ev_site'))
        const ev_sector = String(data.get('ev_sector'))
        const ev_level = String(data.get('ev_level'))
        const ev_grid = String(data.get('ev_grid'))
        const ev_locationDrawingFilename = String(data.get('ev_locationDrawingFilename'))

        if (this.state.grids.some(grid => grid.ev_definition == ev_definition)) {
            const response = await API.patch(`projects/${this.props.project_id}/grids`, {
                ev_definition: ev_definition,
                ev_description: ev_description,
                ev_site: ev_site,
                ev_sector: ev_sector,
                ev_level: ev_level,
                ev_grid: ev_grid,
                ev_locationDrawingFilename: ev_locationDrawingFilename
            })
            if (response.status == 200)
                notifications.add({
                    message: "Grid updated",
                    severity: "success"
                }, 2000)
        }
        else {
            const response = await API.post(`projects/${this.props.project_id}/sectors/${ev_sector}/grids`, {
                ev_definition: ev_definition,
                ev_description: ev_description,
                ev_site: ev_site,
                ev_sector: ev_sector,
                ev_level: ev_level,
                ev_grid: ev_grid,
                ev_locationDrawingFilename: ev_locationDrawingFilename
            })
            if (response.status == 200)
                this.setState({ grids: response.data })
            notifications.add({
                message: "Grid created",
                severity: "success"
            }, 2000)
        }
    }

    async deleteGrid(ev_definition: string) {
        const response = await API.delete(`projects/${this.props.project_id}/grids`, { data: { ev_definition: ev_definition } })
        if (response.status == 204) {
            //Reset state
            this.setState({ grids: [] })
            //Get grids after the delete
            this.getGrids()
            notifications.add({
                message: "Grid Deleted",
                severity: "success"
            }, 2000)
        }
    }

    async downloadGrids() {
        const response = await API.get(`projects/${this.props.project_id}/grids`)
        if (response.status == 200) {

            let gridToExport: any[] = [];

            response.data.map((grid: IGrid) => {
                gridToExport.push({
                    "ev_definition": grid.ev_definition,
                    "ev_description": grid.ev_description,
                    "ev_site": grid.sector?.site_reference,
                    "ev_sector": grid.sector?.ev_reference,
                    "ev_level": grid.ev_level,
                    "ev_grid": grid.ev_grid,
                    "ev_locationDrawingFilename": grid.ev_locationDrawingFilename
                })
            })
            Excel.download({ M_GRID: gridToExport }, 'M_GRID.xlsx')
        }
    }

    render() {
        return (
            <div className=''>
                {this.state.grids.map((grid: IGrid) => {
                    return (
                        <div className=''>
                            <form onSubmit={this.updateGrid.bind(this)}>
                                <div className="grid grid-cols-9">
                                    <input className="border-2 border-double border-zinc-300"
                                        placeholder="Ev Definition"
                                        name="ev_definition"
                                        defaultValue={grid.ev_definition}
                                        // disabled={this.state.grids.some(gridFromTab => gridFromTab.ev_definition == grid.ev_definition) && grid.ev_definition != ""}
                                        required />
                                    <input className="border-2 border-double border-zinc-300"
                                        placeholder="Ev Description"
                                        name="ev_description"
                                        defaultValue={grid.ev_description}
                                        required />
                                    <input className="border-2 border-double border-zinc-300"
                                        placeholder="Ev Site"
                                        name="ev_site"
                                        value={this.state.sectors[0]?.site_reference}
                                        required />
                                    <select className="border-2 border-double border-zinc-300"
                                        placeholder="Ev Sector"
                                        name="ev_sector"
                                        id="ev_sector"
                                        value={grid.sector?.ev_reference}
                                        required>
                                        {this.state.sectors.map((option: ISector) => {
                                            return <option value={option.ev_reference}>{option.ev_reference}</option>
                                        })}
                                    </select>
                                    <input className="border-2 border-double border-zinc-300"
                                        placeholder="Ev Level"
                                        name="ev_level"
                                        defaultValue={grid.ev_level}
                                        required />
                                    <input className="border-2 border-double border-zinc-300"
                                        placeholder="Ev Grid"
                                        name="ev_grid"
                                        defaultValue={grid.ev_grid}
                                        required />
                                    <input className="border-2 border-double border-zinc-300"
                                        placeholder="FileName"
                                        name="ev_locationDrawingFilename"
                                        defaultValue={grid.ev_locationDrawingFilename}
                                    />
                                    <div className='grid grid-cols-2 justify-items-center'>
                                        <button type="submit">
                                            <div className="w-8 h-8 bg-alis-1 rounded-full flex justify-center items-center p-2">
                                                <BsCheckLg color='white' size={25}></BsCheckLg>
                                            </div>
                                        </button>

                                        <button type='button' onClick={() => this.deleteGrid(grid.ev_definition)}>
                                            <div className="w-8 h-8 bg-alis-2 rounded-full flex justify-center items-center p-2">
                                                <RiDeleteBin7Fill color='white' size={25}></RiDeleteBin7Fill>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>)
                })}

                <div className='grid grid-cols-2 justify-items-center'>
                    <button className='bg-alis-2 font-semibold mt-2 rounded-md text-white px-2' onClick={() => this.addGrid()}>Add row</button>
                    <button className='bg-alis-3 font-semibold mt-2 rounded-lg text-white px-2' onClick={() => this.downloadGrids()}>Export Data</button>
                </div>

            </div>
        )
    }

    componentDidMount() {
        this.getSectors()
        this.getGrids()
    }
}
