import { Component } from 'react'

import Loader from '@components/common/Loader'

import ClassTemplate from './ClassTemplate'

import notifications, { add } from "@services/NotificationService";

import API from '@services/ApiService';

import { IClass } from '@utils/types'

interface IProps {
    project_id: string
    handleClick: () => void
}

export default class Docs extends Component<IProps, {}> {
    state = {
        isLoading: false,
        uploaded: false,
        toggleState: 1,
        classes: []
    }

    render() {

        const toggleTab = (index: any) => {
            this.setState({ toggleState: index });
        };

        return (

            <div className="flex items-center justify-center text-center h-screen">

                <div className='bg-clip-padding py-10 px-32 bg-white opacity-80 mb-28'>

                        <Loader isLoading={this.state.isLoading}>
                            <div className="flex">
                                <div className="flex-grow">
                                    <button onClick={this.props.handleClick} className='text-black float-right text-lg'>X</button>
                                    <h3 className="w-full text-center">UPLOAD TEMPLATES</h3>
                                    <div className="container">
                                        <div className="bloc-tabs">
                                            <button
                                                className={this.state.toggleState === 1 ? "tabs active-tabs" : "tabs"}
                                                onClick={() => toggleTab(1)}
                                            >
                                                OVERVIEW
                                            </button>
                                            <button
                                                className={this.state.toggleState === 2 ? "tabs active-tabs" : "tabs"}
                                                onClick={() => toggleTab(2)}
                                            >
                                                DRAWING
                                            </button>
                                        </div>

                                        <div className="content-tabs">

                                            <div className={this.state.toggleState === 1 ? "content active-content" : "content"} >
                                                <div className="grid grid-cols-3 p-2 mb-4 font-bold">
                                                    <h1>CLASS NAME</h1>
                                                    <h1>HANDLE BLOCK</h1>
                                                    <h1>TEMPLATE</h1>
                                                </div>
                                                {this.state.classes.map((actualClass: IClass) => {
                                                    return <ClassTemplate submitHandler={this.uploadOverview.bind(this)} class={actualClass} template={actualClass.overview}></ClassTemplate>
                                                })}
                                            </div>

                                            <div className={this.state.toggleState === 2 ? "content  active-content" : "content"} >
                                                <div className="grid grid-cols-3 p-2 mb-4 font-bold">
                                                    <h1>CLASS NAME</h1>
                                                    <h1>HANDLE BLOCK</h1>
                                                    <h1>TEMPLATE</h1>
                                                </div>
                                                {this.state.classes.map((actualClass: IClass) => {
                                                    return <ClassTemplate submitHandler={this.uploadDrawing.bind(this)} class={actualClass} template={actualClass.drawing}></ClassTemplate>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Loader>
                </div>
                <style>{`
                    *, ::before, ::after {
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0;
                    }

                    .container {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: 700px;
                        height: auto;
                        max-height: 400px;
                        background: #f1f1f1;
                        margin: 20px auto 0;
                        word-break: break-all;
                        border: 1px solid rgba(0, 0, 0, 0.274);
                    }
                    
                    .bloc-tabs {
                        display: flex;
                    }
                    .tabs {
                        padding: 15px;
                        text-align: center;
                        width: 50%;
                        background: rgba(128, 128, 128, 0.075);
                        cursor: pointer;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.274);
                        box-sizing: content-box;
                        position: relative;
                        outline: none;
                    }
                    .tabs:not(:last-child){
                        border-right: 1px solid rgba(0, 0, 0, 0.274);
                    }
                    
                    .active-tabs  {
                        background: white;
                        border-bottom: 1px solid transparent;
                    }
                    
                    .active-tabs::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: -5px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: calc(100% + 2px);
                        height: 5px;
                        background: rgb(43, 210, 255);
                    }

                    .content-tabs {
                        background: white;
                        overflow: auto
                    }
                    .content {
                        background: white;
                        padding: 20px;
                        width: 100%;
                        height: 100%;
                        display: none;
                    }
                    .content h2 {
                        padding: 0px 0 5px 0px;
                    }
                    .content hr {
                        width: 100px;
                        height: 2px;
                        background: #222;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    .content p {
                        width: 100%;
                        height: 100%;
                    }
                    .active-content {
                        display: block;
                    }
                `}</style>
            </div>
        )
    }

    async uploadOverview(bloc: string, file: File, classToUpdate: IClass) {
        try {
            const form = new FormData()
            form.append('file', file)
            form.append('bloc', bloc)

            const response = await API.post(`/projects/${this.props.project_id}/classes/${classToUpdate.name}/overview`, form)
            if (response.status === 201 || response.status === 200) {
                
                this.setState({ uploaded: true })

                notifications.add({
                    message: 'Overview uploaded!',
                    severity: 'success'
                })
            }
        } catch (error) {
            console.error(error);
        }
        this.setState({ isLoading: false })
    }

    async uploadDrawing(bloc: string, file: File, classToUpdate: IClass) {
        try {
            const form = new FormData()
            form.append('file', file)
            form.append('bloc', bloc)

            const response = await API.post(`/projects/${this.props.project_id}/classes/${classToUpdate.name}/drawing`, form)
            if (response.status === 201 || response.status === 200) {
                
                this.setState({ uploaded: true })

                notifications.add({
                    message: 'Drawing uploaded!',
                    severity: 'success'
                },2000)
            }
        } catch (error) {
            console.error(error);
        }
        this.setState({ isLoading: false })
    }

    async getClasses() {
        try {
            const response = await API.get(`/projects/${this.props.project_id}/classes`)
            if (response.status === 200 || response.status === 201) {

                const classes = response.data

                this.setState({ classes })

            }
        } catch (error) {
            add({
                message: 'Failed to get classes!',
                severity: "error"
            },2000)
        }
    }

    componentDidMount() {
        this.getClasses()
    }
}
