import { Component } from 'react';
import { RiDeleteBin7Fill } from 'react-icons/ri';
import { IoMdAdd } from 'react-icons/io'
import { User } from '@app/services/UserService';
import { ISystem } from '@app/utils/types';

interface IProps {
    clicked: (data: any) => void
    text?: string
    data?: any
    system: ISystem
}

interface IState {

}

export default class BtnCellRenderer extends Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        this.props.clicked(this.props.data);
    }

    authorisations() {
        if (User.isAdmin && this.props.text == "addToSystem") 
            return <IoMdAdd size={30} className='pt-1 pr-1 text-gray-500 hover:text-gray-900' onClick={this.btnClickedHandler}></IoMdAdd>
        
        else if (User.isAdmin && this.props.text != "addToSystem") 
            return <RiDeleteBin7Fill size={30} className='pt-1 pr-1 text-gray-500 hover:text-gray-900' onClick={this.btnClickedHandler}></RiDeleteBin7Fill>
        
        else if (this.props.system != undefined) {
            if (this.props.system.user == User.username && this.props.text == "addToSystem") 
                return <IoMdAdd size={30} className='pt-1 pr-1 text-gray-500  hover:text-gray-900' onClick={this.btnClickedHandler}></IoMdAdd>
            
            else if (this.props.system.user == User.username && this.props.text != "addToSystem") 
                return <RiDeleteBin7Fill size={30} className='pt-1 pr-1 text-gray-500  hover:text-gray-900' onClick={this.btnClickedHandler}></RiDeleteBin7Fill>
        }
        else return
    }

    render() {
        return (
            <div>
                {this.authorisations()}
            </div>
        )
    }
}