import { Component } from 'react'

import loader from '@assets/ISIS_LOGO.svg'

interface ILoaderProps {
    isLoading: boolean
}
interface ILoaderState { }

export default class Loader extends Component<ILoaderProps, ILoaderState> {

    render() {

        return (
            <div className="relative h-full w-full overflow-hidden">
                {this.props.isLoading ?
                    <div className="absolute h-full w-full bg-back z-100 flex align-middle">
                        <img className="m-auto w-1/6 h-1/6 loader" src={loader} alt="Loader logo" />
                    </div> : null
                }
                {this.props.children}

                <style>{`
                    @keyframes flip {
                    0% {
                        transform: scaleX(1);
                    }

                    100% {
                        transform: scaleX(-1);
                    }
                    }

                    .loader {
                    width: 10%;
                    animation-name: flip;
                    Animation-duration: 1.5s;
                    Animation-iteration-count: infinite;
                    animation-direction: alternate;
                    animation-timing-function: ease-in-out;
                    }
                `}
                </style>
            </div>

        )
    }
}

