import { Component } from 'react'

import { FaTimes as XIcon } from 'react-icons/fa';

export default class mainModal extends Component<{onClose:()=>void}> {
    
    render() {
        return (
            <div className="fixed z-100 flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-800 w-1/2">
                <div className="w-full flex">
                    <div className="flex-grow"></div>
                    <button className="h-6 w-6 hover:bg-opacity-20 hover:bg-white" onClick={this.props.onClose}><XIcon className="h-full w-full" /></button>
                </div>
                <div className="w-full flex flex-col p-3">

                    <div className="p-4 shadow-lg rounded-md text-left self-center" style={{ maxWidth: "400px" }}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}
